var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade-bottom-2x" } }, [
    _c("div", [
      _c(
        "div",
        { staticClass: "flex vx-col w-full lg:w-1/1 sm:w-1/1 mb-base" },
        [
          _c("vx-card", [
            _c(
              "div",
              { staticClass: "pt-2 pb-2 pr-2 pl-2", attrs: { align: "right" } },
              [
                _c("vs-button", {
                  attrs: {
                    color: "secondary",
                    type: "filled",
                    "icon-pack": "feather",
                    icon: "icon-x",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.closeLicenseAgreement()
                    },
                  },
                }),
                _c(
                  "vs-row",
                  { staticStyle: { padding: "5px" } },
                  [
                    _c(
                      "vs-col",
                      {
                        staticClass: "text-left",
                        attrs: {
                          "vs-type": "flex",
                          "vs-justify": "lwft",
                          "vs-align": "left",
                          "vs-w": "6",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "agreement-header-left vx-logo cursor-pointer flex items-center router-link-active",
                          },
                          [
                            _c("img", {
                              staticClass: "mr-4 fill-current text-secondary",
                              attrs: {
                                src: "/logo.svg",
                                width: "60",
                                height: "60",
                              },
                            }),
                            _c("span", { staticClass: "text-secondary" }, [
                              _vm._v("PATHADVICE"),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "vs-col",
                      {
                        staticClass: "text-right",
                        attrs: {
                          "vs-type": "block",
                          "vs-justify": "right",
                          "vs-align": "right",
                          "vs-w": "6",
                        },
                      },
                      [
                        _vm._v("\n              Lizenzvereinbarung "),
                        _c("br"),
                        _vm._v("\n              PATHADVICE CONNECT "),
                        _c("br"),
                        _vm._v("\n              A3100D\n            "),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "agreement-text mt-5 text-left justify" },
                  [
                    _c("p", { staticClass: "mt-5" }, [_vm._v("zwischen der")]),
                    _c("p", { staticClass: "mt-5" }, [
                      _vm._v("\n              PATHADVICE International GmbH"),
                      _c("br"),
                      _vm._v("\n              Serlesweg 3"),
                      _c("br"),
                      _vm._v("\n              6161 Natters"),
                      _c("br"),
                      _vm._v("\n              Austria"),
                      _c("br"),
                      _vm._v("\n              (nachfolgend „PA“ genannt)"),
                      _c("br"),
                    ]),
                    _c("p", { staticClass: "mt-5 mb-5" }, [
                      _vm._v(
                        "\n              und dem PARTNER nachfolgend „PARTNER“ genannt"
                      ),
                      _c("br"),
                      _vm._v(
                        "\n              gemeinsam nachfolgend „PARTEIEN“ genannt\n            "
                      ),
                    ]),
                    _c("strong", [_vm._v("1. Vertragsgegenstand")]),
                    _c("p", { staticClass: "mt-2 mb-5" }, [
                      _vm._v(
                        "\n              PA ist alleinige Eigentümerin der PATHADVICE Technologie sowie aller zugehörigen Handelsmarken und räumt dem PARTNER das nicht ausschließliche,\n              einfache Recht zur Nutzung der in Anlage 1 beschriebenen PA-Services zur gewerblichen Verwendung im Rahmen seiner Kundenkommunikation und\n              Kunden-Akquise für die Laufzeit dieser Lizenzvereinbarung ein. Eine Weiter-Lizensierung oder Weitergabe an Dritte sowie die Auswertung oder\n              Änderungen des Quellcodes ist nicht zulässig.\n            "
                      ),
                    ]),
                    _c("strong", [_vm._v("2. Vertragspflichten der PA ")]),
                    _c("p", { staticClass: "mt-2 mb-5" }, [
                      _vm._v(
                        "\n              PA stellt diese Services basierend auf den Service Levels in Anlage 2 zur Verfügung und ist berechtigt, zur Erbringung von Leistungen\n              Subunternehmer hinzuzuziehen.\n            "
                      ),
                    ]),
                    _c("strong", [_vm._v("3. Vertragspflichten des Partners")]),
                    _c("p", { staticClass: "mt-2 mb-5" }, [
                      _vm._v(
                        "\n              Der PARTNER wird PA bei der Erfüllung der vertraglichen Leistungen insbesondere durch die Bereitstellung eines internen Ansprechpartners,\n              fachkundiger Mitarbeiter und Informationen und Kommunikationsmittel auf eigene Kosten unterstützen.\n              "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n              Der PARTNER ist im Zusammenhang mit der Nutzung von PA – Services für die Einhaltung der geltenden rechtlicher Vorschriften verantwortlich und\n              wird über PA-Systeme keine Informationen austauschen die gegen gesetzliche Verbote, die guten Sitten (insbesondere pornografische, rassistische,\n              ausländerfeindliche, rechtsradikale oder sonstige verwerfliche Inhalte) oder Rechte Dritter (insbesondere Persönlichkeits-, Marken-, Namens- und\n              Urheberrechte) verstoßen.\n\n              "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n              Der Partner wird zur Einschulung auf PA-Produkte, die von PA zur Verfügung gestellten Schulungsmaterialien nutzen. In bestimmten Fällen ist für\n              die Nutzung von PA-Produkten die erfolgreiche Absolvierung von Schulungsmaßnahmen erforderlich.\n              "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n              Der PARTNER stimmt zu, dass PA Namen und Logo des PARTNERS für werbliche Zwecke als Referenz nutzt sowie, dass PA die Daten des PARTNERS zur\n              laufenden Weiterentwicklung seiner Services auf anonymisierte Basis während und nach der Zusammenarbeit mit dem PARTNER nutzt.\n              "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n              Der PARTNER wird die PA Marken im Rahmen der Nutzung der PA-Services entsprechend Anlage 3 darstellen.\n            "
                      ),
                    ]),
                    _c("strong", [_vm._v("4. Datenschutz")]),
                    _c("p", { staticClass: "mt-2 mb-5" }, [
                      _vm._v(
                        "\n              Im Zusammenhang mit der Nutzung von PA-Services agiert der PARTNER als Verantwortlicher im Sinne der DSGVO. PA agiert als Auftrags\n              Datenverarbeiter gemäß Art. 28 DSGVO und entsprechend dem Auftragsverarbeitungsvertrag in Anlage 4. Der PARTNER stimmt zu, dass PA Daten zur\n              Verbesserung seiner Services auf pseudoanonymisierter und statistischer Basis auswertet.\n            "
                      ),
                    ]),
                    _c("strong", [_vm._v("5. Vergütung")]),
                    _c("p", { staticClass: "mt-2 mb-5" }, [
                      _vm._v(
                        "\n              Für die in dieser Lizenzvereinbarung vereinbarten Leistungen stellt PA Rechnungen in der Höhe und zu den Konditionen wie in Anlage 5\n              dargestellt.\n            "
                      ),
                    ]),
                    _c("strong", [_vm._v("6. Leistungsänderungen ")]),
                    _c("p", { staticClass: "mt-2 mb-5" }, [
                      _vm._v(
                        "\n              Leistungsänderungen werden in zusätzlichen Anlagen zu dieser Lizenzvereinbarung vereinbart.\n              "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n              PA ist berechtigt, Erweiterung des Leistungsumfangs ohne Abstimmung durchzuführen, wenn dies keine technischen oder rechtlichen Änderungen für\n              den PARTNER hervorruft. "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n              Leistungsänderungen, die technische oder rechtliche Änderungen beim PARTNER hervorrufen werden von PA mit 1 – Monatsfrist vorangekündigt.\n              "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n              Leistungsänderungen, die durch den PARTNER beauftragt werden, werden entsprechend dem vorangehenden Anbot der PA umgesetzt und als\n              Individualisierungsaufwand in Rechnung gestellt.\n            "
                      ),
                    ]),
                    _c("strong", [_vm._v("7. Mängel")]),
                    _c("p", { staticClass: "mt-2 mb-5" }, [
                      _vm._v(
                        "\n              Der PARTNER hat binnen 14 Tagen nach Aufforderung durch angemessene Abnahmetests die Funktion des Vertragsgegenstandes zu überprüfen.\n              "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n              Der PARTNER ist verpflichtet, Mängel unverzüglich nach Auftreten an PA zu melden. Die Bearbeitung von Mängeln durch PA ist in Anlage 2 geregelt.\n              Die Einmeldung eines Mangels berechtigt den PARTNER nicht zu einer Reduktion der Vergütung bis zur Mängelbehebung.\n            "
                      ),
                    ]),
                    _c("strong", [
                      _vm._v("8. Zahlungsverzug durch den PARTNER"),
                    ]),
                    _c("p", { staticClass: "mt-2 mb-5" }, [
                      _vm._v(
                        "\n              Der PARTNER ist verpflichtet, Lizenzgebühren gemäß dieser Vereinbarung so zu überweisen, dass diese fristgerecht auf dem Konto der PA einlangen.\n              Im Fall der nicht fristgerechten Überweisung ist PA berechtigt, wahlweise den Account des PARTNERS bis zum Eintreffen der Zahlung zu sperren\n              oder gänzlich zu deaktivieren. Die Kündigungsfristen gemäß dieser Vereinbarung bleiben für diesem Fall aufrecht.\n            "
                      ),
                    ]),
                    _c("strong", [_vm._v("9. Laufzeit und Kündigung")]),
                    _c("p", { staticClass: "mt-2 mb-5" }, [
                      _vm._v(
                        "\n              Diese Lizenzvereinbarung tritt mit der Unterzeichnung durch die Parteien in Kraft und wird auf unbestimmte Zeit geschlossen.\n              "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n              Die PARTEIEN können diese Lizenzvereinbarung einschließlich aller ihrer Anhänge bei monatlicher Zahlung unter Einhaltung einer 1-monatigen\n              Kündigungsfrist, beginnend mit dem 1. des Folgemonats per schriftlicher Mitteilung, an die in Anlage 6 genannte E-Mail-Adresse kündigen.\n              "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n\n              Die PARTEIEN können diese Lizenzvereinbarung einschließlich aller ihrer Anhänge bei jährlicher Zahlung unter Einhaltung einer 1-monatigen\n              Kündigungsfrist, beginnend mit dem 2. Jahr per schriftlicher Mitteilung, an die in Anlage 6 genannte E-Mail-Adresse kündigen. "
                      ),
                      _c("br"),
                      _c("br"),
                      _c("strong", [_vm._v("Kündigung aus wichtigem Grund")]),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n\n              Jede Partei kann diese Lizenzvereinbarung mit sofortiger Wirkung aus wichtigem Grund kündigen. Ein wichtiger Grund liegt insbesondere vor, wenn\n              die andere Partei ihre Pflichten nach dieser Lizenzvereinbarung oder nach anwendbarem Recht in wesentlichem Umfang nicht beachtet oder nicht\n              erfüllt oder wenn die andere Partei\n\n              "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n              (1) freiwillig oder unfreiwillig ein Konkurs-, Insolvenz-, Liquidations- oder ähnliches Verfahren einleitet (oder je nach Fall, Gegenstand\n              hiervon wird) oder einen entsprechenden Antrag einreicht (und der Antrag, sofern es sich um ein freiwilliges Einleiten handelt, nicht innerhalb\n              von sechzig (60) Kalendertagen abgelehnt wird), "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n              (2) die Bestellung eines Verwalters, Treuhänders, Betreuers oder einer ähnlichen Person beantragt oder dieser zustimmt, für sich oder in Bezug\n              auf einen wesentlichen Teil ihres Eigentums oder Vermögens, "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n              (3) die Auflösung oder Liquidation seines Geschäftsbetriebs oder seiner Geschäfte einleitet, oder "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n              (4) gesellschaftsrechtliche Handlungen zur Durchführung des Vorgenannten vornimmt.\n              "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n              Kündigungen erfolgen in schriftlicher Form über die in Anlage 6 genannten Kommunikationskanäle. "
                      ),
                      _c("br"),
                      _c("br"),
                      _c("strong", [_vm._v("Kündigungsfolgen")]),
                      _vm._v(
                        "\n              Der PARTNER hat mit dem letzten Tag der Laufzeit dieser Lizenzvereinbarung die Nutzung der PA-Services einzustellen. "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n              Der PARTNER wird etwaige Datenexporte, soweit zulässig, vor der Beendigung der Laufzeit durchführen und nimmt zur Kenntnis, dass der\n              LogIn-Bereich ab dem 1. Tag nach Beendigung der Laufzeit nicht mehr nutzbar ist.\n              "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n              Der PARTNER hat nach Ermessen der PA unverzüglich alle vertraulichen Informationen die er in seiner Eigenschaft als PARTNER in irgendeiner Form\n              erhalten hat entweder zu vernichten oder an PA zurückzugeben, sofern nicht gemäß geltendem Recht anders vorgesehen.\n            "
                      ),
                    ]),
                    _c("strong", [_vm._v("10. Haftung")]),
                    _c("p", { staticClass: "mt-2 mb-5" }, [
                      _vm._v(
                        "\n              PA gibt keine Zusicherung oder Garantie ab, dass die PA-Technologie frei von Mängeln und/oder Fehlern ist und stellt die PA-Services auf Basis\n              der Service Levels in Anlage 2, jedoch ohne weitere ausdrücklichen oder konkludenten Garantien bereit.\n              "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n              Wenn Kardinalpflichten durch leichte Fahrlässigkeit verletzt werden und in der Folge die Erreichung des Ziels der Lizenzvereinbarung gefährdet\n              ist oder wenn Pflichten, deren Erfüllung wesentliche Voraussetzung für die ordnungsgemäße Erfüllung der Lizenzvereinbarung ist durch leicht\n              fahrlässiges Verschulden verletzt werden ist die Haftung der PA auf den für die Lizenzvereinbarung typischen vorhersehbaren Schaden beschränkt.\n              In allen sonstigen Fällen ist die Haftung für Schäden aufgrund von leichter Fahrlässigkeit ausgeschlossen.\n            "
                      ),
                    ]),
                    _c("strong", [_vm._v("11. Geheimhaltung")]),
                    _c("p", { staticClass: "mt-2 mb-5" }, [
                      _vm._v(
                        "\n              Die PARTEIEN dürfen vertrauliche Informationen nur dann offenlegen, wenn ihre Offenlegung nach geltendem Recht vorgeschrieben ist. "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n              Die PARTEIEN beschränken den Zugang zu vertraulichen Informationen auf Personen, die diesen Zugang begründetermaßen benötigen, und stellen\n              sicher, dass sämtliche Personen mit Zugang zu den vertraulichen Informationen zur strengen Geheimhaltung verpflichtet sind. "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n              Die PARTEIEN werden der jeweils andere PARTEI unverzüglich über Sicherheitsverstöße mit möglicher Kompromittierung von vertraulichen\n              Informationen, über die widerrechtliche Aneignung, Offenlegung oder Verwendung von vertraulichen Informationen informieren und unternehmen auf\n              eigene Kosten im angemessenen Rahmen jegliche Schritte zur Begrenzung, Beendigung oder sonstigen Bekämpfung dieser Zweckentfremdung, Offenlegung\n              oder Verwendung.Die PARTEIEN werden der jeweils andere PARTEI unverzüglich über Sicherheitsverstöße mit möglicher Kompromittierung von\n              vertraulichen Informationen, über die widerrechtliche Aneignung, Offenlegung oder Verwendung von vertraulichen Informationen informieren und\n              unternehmen auf eigene Kosten im angemessenen Rahmen jegliche Schritte zur Begrenzung, Beendigung oder sonstigen Bekämpfung dieser\n              Zweckentfremdung, Offenlegung oder Verwendung.\n            "
                      ),
                    ]),
                    _c("strong", [_vm._v("12. Mitteilungen")]),
                    _c("p", { staticClass: "mt-2 mb-5" }, [
                      _vm._v(
                        "\n              Alle Mitteilungen, Anträge, Forderungen und sonstigen Nachrichten im Rahmen dieser Lizenzvereinbarung sind schriftlich an die in Anlage 6\n              genannten Adressen zu richten.\n            "
                      ),
                    ]),
                    _c("strong", [_vm._v("13. Salvatorische Klausel")]),
                    _c("p", { staticClass: "mt-2 mb-5" }, [
                      _vm._v(
                        "\n              Sollte eine Bestimmung dieser Lizenzvereinbarung ungültig oder nicht durchsetzbar sein oder werden, so bleibt die Gültigkeit oder\n              Durchsetzbarkeit der übrigen Bestimmungen hiervon unberührt. Die ungültige oder nicht durchsetzbare Bestimmung gilt als durch eine geeignete und\n              angemessene Bestimmung, die der wirtschaftlichen Absicht und dem Zweck der ungültigen oder nicht durchsetzbaren Bestimmung, soweit gesetzlich\n              zulässig, am nächsten kommt, ersetzt.\n            "
                      ),
                    ]),
                    _c("strong", [_vm._v("14. Rechtswahl und Gerichtsstand ")]),
                    _c("p", { staticClass: "mt-2 mb-5" }, [
                      _vm._v(
                        "\n              Diese Lizenzvereinbarung unterliegt deutschem Recht für Vertragspartner mit Sitz in der Europäischen Union und dem Recht von Großbritannien für\n              Vertragspartner außerhalb der Europäischen Union. Der ausschließliche Gerichtsstand für alle Streitigkeiten und Verfahren, die aus oder in\n              Zusammenhang mit dieser Lizenzvereinbarung entstehen, ist Innsbruck.\n            "
                      ),
                    ]),
                    _c("strong", [
                      _vm._v(
                        "Anlage 1 / Leistungsbeschreibung PATHADVICE Connect "
                      ),
                    ]),
                    _c("br"),
                    _c("div", { staticClass: "mt-5 mb-5" }, [
                      _c("div", { staticClass: "mb-3" }, [
                        _c("div", { staticClass: "underline mb-5" }, [
                          _vm._v("Account Management"),
                        ]),
                        _vm._v(
                          "\n                - Erstellung PARTNER-Account inkls. 1 User"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                - Optional: Erweiterung durch beliebig viele User (kostenpflichtig) "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                - Optional: Zubuchung weiterer Services (kostenpflichtig)\n                "
                        ),
                        _c("br"),
                        _c("br"),
                      ]),
                      _c("div", { staticClass: "mb-3" }, [
                        _c("div", { staticClass: "underline mb-5" }, [
                          _vm._v("Login Bereich"),
                        ]),
                        _vm._v("\n                - Dashboard "),
                        _c("br"),
                        _vm._v("\n                - Anzeige Website-Besucher"),
                        _c("br"),
                        _vm._v("\n                - Dialogfeld Konfiguration"),
                        _c("br"),
                        _c("br"),
                      ]),
                      _c("div", { staticClass: "mb-3" }, [
                        _c("div", { staticClass: "underline mb-5" }, [
                          _vm._v("Website Besucher Kommunikation"),
                        ]),
                        _vm._v(
                          "\n                - Dialogfeld zur direkten Kontaktaufnahme mit Berater"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                - Invite - Link zur digitalen Beratung"
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          "\n                - Browserbasierte Videoberatung (CoBrowsing)"
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._v("\n                - Beidseitige Steuerung"),
                        _c("br"),
                        _c("br"),
                        _vm._v("\n                - Pointer Funktion"),
                        _c("br"),
                        _c("br"),
                      ]),
                    ]),
                    _c("strong", [
                      _vm._v("Anlage 2 - A5000D_Service Level Agreement"),
                    ]),
                    _c("br"),
                    _c("div", { staticClass: "mt-5 mb-5" }, [
                      _c("strong", [
                        _vm._v(
                          "Servicezeiten: Montag bis Freitag an Arbeitstagen, CET 09:00 Uhr bis 17:00 Uhr"
                        ),
                      ]),
                      _c("p", { staticClass: "mb-3" }, [
                        _vm._v("\n                E-Mail: "),
                        _c(
                          "a",
                          { attrs: { href: "mailto:support@pathadvice.at" } },
                          [_vm._v("support@pathadvice.at")]
                        ),
                        _c("br"),
                        _vm._v("\n                Chat: Im Login Bereich"),
                        _c("br"),
                        _c("br"),
                      ]),
                    ]),
                    _c("strong", [
                      _vm._v("Severity Level und Reaktionszeiten:"),
                    ]),
                    _c("br"),
                    _c(
                      "vs-row",
                      { staticClass: "left-solid-border" },
                      [
                        _c(
                          "vs-col",
                          {
                            staticClass: "bg-red solid-border text-left",
                            attrs: {
                              "vs-type": "flex",
                              "vs-justify": "center",
                              "vs-align": "center",
                              "vs-w": "4",
                            },
                          },
                          [_vm._v(" Dringend ")]
                        ),
                        _c(
                          "vs-col",
                          {
                            staticClass: "solid-border",
                            attrs: {
                              "vs-type": "flex",
                              "vs-justify": "center",
                              "vs-align": "center",
                              "vs-w": "4",
                            },
                          },
                          [_vm._v(" Umfassender Betriebsausfall ")]
                        ),
                        _c(
                          "vs-col",
                          {
                            staticClass: "solid-border",
                            attrs: {
                              "vs-type": "flex",
                              "vs-justify": "center",
                              "vs-align": "center",
                              "vs-w": "4",
                            },
                          },
                          [_vm._v(" Problemlösung: 1 Arbeitstag ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "vs-row",
                      { staticClass: "left-solid-border" },
                      [
                        _c(
                          "vs-col",
                          {
                            staticClass: "bg-orange solid-border text-left",
                            attrs: {
                              "vs-type": "flex",
                              "vs-justify": "center",
                              "vs-align": "center",
                              "vs-w": "4",
                            },
                          },
                          [_vm._v(" Hoch ")]
                        ),
                        _c(
                          "vs-col",
                          {
                            staticClass: "solid-border",
                            attrs: {
                              "vs-type": "flex",
                              "vs-justify": "center",
                              "vs-align": "center",
                              "vs-w": "4",
                            },
                          },
                          [
                            _vm._v(
                              "\n                Betriebsunterbrechung bei mehreren Anwendern\n              "
                            ),
                          ]
                        ),
                        _c(
                          "vs-col",
                          {
                            staticClass: "solid-border",
                            attrs: {
                              "vs-type": "flex",
                              "vs-justify": "center",
                              "vs-align": "center",
                              "vs-w": "4",
                            },
                          },
                          [_vm._v(" Problemlösung: 1 Arbeitstag ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "vs-row",
                      { staticClass: "left-solid-border" },
                      [
                        _c(
                          "vs-col",
                          {
                            staticClass: "bg-yellow solid-border text-left",
                            attrs: {
                              "vs-type": "flex",
                              "vs-justify": "center",
                              "vs-align": "center",
                              "vs-w": "4",
                            },
                          },
                          [_vm._v(" Mittel ")]
                        ),
                        _c(
                          "vs-col",
                          {
                            staticClass: "solid-border",
                            attrs: {
                              "vs-type": "flex",
                              "vs-justify": "center",
                              "vs-align": "center",
                              "vs-w": "4",
                            },
                          },
                          [
                            _vm._v(
                              "\n                Fehler tritt in bestimmten Konstellationen auf"
                            ),
                          ]
                        ),
                        _c(
                          "vs-col",
                          {
                            staticClass: "solid-border",
                            attrs: {
                              "vs-type": "flex",
                              "vs-justify": "center",
                              "vs-align": "center",
                              "vs-w": "4",
                            },
                          },
                          [_vm._v(" Problemlösung: 10 Arbeitstage ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "vs-row",
                      { staticClass: "left-bottom-solid-border" },
                      [
                        _c(
                          "vs-col",
                          {
                            staticClass: "bg-green solid-border text-left",
                            attrs: {
                              "vs-type": "flex",
                              "vs-justify": "center",
                              "vs-align": "center",
                              "vs-w": "4",
                            },
                          },
                          [_vm._v(" Niedrig ")]
                        ),
                        _c(
                          "vs-col",
                          {
                            staticClass: "solid-border",
                            attrs: {
                              "vs-type": "flex",
                              "vs-justify": "center",
                              "vs-align": "center",
                              "vs-w": "4",
                            },
                          },
                          [_vm._v(" Gering Beeinträchtigung der Services ")]
                        ),
                        _c(
                          "vs-col",
                          {
                            staticClass: "solid-border",
                            attrs: {
                              "vs-type": "flex",
                              "vs-justify": "center",
                              "vs-align": "center",
                              "vs-w": "4",
                            },
                          },
                          [_vm._v(" Problemlösung: 10 Arbeitstage ")]
                        ),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "mt-5 mb-5" }, [
                      _vm._v(
                        "Wird ein Ticket außerhalb der Arbeitszeiten aufgegeben beginnen Service-Laufzeiten mit dem folgenden Arbeitstag."
                      ),
                    ]),
                    _c("strong", [
                      _vm._v("Anlage 3 / A6000D_Markenverwendung"),
                    ]),
                    _c("br"),
                    _c("div", { staticClass: "mt-5 mb-5" }, [
                      _vm._v(
                        "Die Wort-Bild-Marke ist – wo immer möglich – in ihrer Maximalausprägung (abgebildete Standardversion) einzusetzen."
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-row items-center flex-wrap justify-around w-full",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-row justify-center style-guide",
                          },
                          [
                            _c("div", [
                              _c("img", {
                                staticClass: "mr-4 fill-current text-secondary",
                                attrs: {
                                  src: "/logo.classic.svg",
                                  width: "120",
                                  height: "120",
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "classic" }, [
                              _c(
                                "div",
                                { staticClass: "mt-5 text-secondary" },
                                [_vm._v("PATHADVICE")]
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "flex flex-col",
                            staticStyle: { "font-weight": "400" },
                          },
                          [
                            _c("div", {
                              staticStyle: {
                                width: "160px",
                                height: "82px",
                                "background-color": "#12598d",
                              },
                            }),
                            _c("div", { staticStyle: { padding: "10px" } }, [
                              _c("strong", [_vm._v("Primär")]),
                              _c("br"),
                              _c("br"),
                              _vm._v("\n                  HEX: #12598d "),
                              _c("br"),
                              _vm._v("\n                  RGB: 18/ 89/ 141 "),
                              _c("br"),
                              _vm._v(
                                "\n                  CMYK: 93 / 62 / 21 / 6\n                "
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "mt-12" }, [
                      _c("u", [_vm._v("Schriftart:")]),
                      _vm._v(" Lato SemiBold"),
                    ]),
                    _c("div", { staticClass: "mt-12" }, [
                      _c("strong", [
                        _vm._v(
                          "Anlage 4 / A3200D_Auftragsdatenverarbeitungsvertrag"
                        ),
                      ]),
                      _c("br"),
                    ]),
                    _c("div", { staticClass: "mt-6" }, [
                      _vm._v("zwischen dem Auftragsverarbeiter"),
                    ]),
                    _c("div", { staticClass: "mt-6" }, [
                      _c("strong", [_vm._v("PATHADVICE International GmbH")]),
                      _c("br"),
                      _vm._v("\n              Serlesweg 3"),
                      _c("br"),
                      _vm._v("\n              6161 Natters"),
                      _c("br"),
                      _vm._v("\n              Austria"),
                      _c("br"),
                      _c("br"),
                      _vm._v("\n              (nachfolgend „PA“ genannt)"),
                      _c("br"),
                      _c("br"),
                      _vm._v("\n              und dem „PARTNER“ "),
                      _c("br"),
                      _vm._v(
                        "\n              gemeinsam nachfolgend „PARTEIEN“ genannt\n            "
                      ),
                    ]),
                    _c("div", { staticClass: "mt-12" }, [
                      _c("strong", [_vm._v("1. Gegenstand der Vereinbarung")]),
                      _c("p", [
                        _vm._v(
                          "Gegenstand, Art, Umfang sowie Zweck der Datenverarbeitung ergibt sich aus dem zwischen PA und dem PARTNER geschlossenen Lizenzvertrag."
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n                Die Verarbeitung der Daten umfasst das Erheben, Erfassen, Ordnen, Speichern, Anpassen, Verändern, Auslesen, Abfragen, Bereitstellen,\n                Abgleichen, Einschränken, Löschen und Vernichten von Daten.\n              "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n                Die erhobenen Daten werden dem PARTNER im Rahmen einer cloud-basierten Softwarelösung zur Verfügung gestellt. Zur Wartung und Unterstützung\n                des PARTNERS erhalten Mitarbeiter der PA Zugang zu diesen Daten.\n              "
                        ),
                      ]),
                      _c("p", [
                        _c("u", [
                          _vm._v(
                            "Folgende Datenkategorien werden im Rahmen der Kooperation verarbeitet:"
                          ),
                        ]),
                      ]),
                      _c("p", [
                        _vm._v(
                          "Kunden, Interessenten, Lieferanten, Ansprechpartner, Beschäftigte, Website-Besucherdaten;"
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "mt-12" }, [
                      _c("strong", [_vm._v("2. Dauer der Vereinbarung")]),
                      _c("p", [
                        _vm._v(
                          "Dieser Vertrag wird auf unbestimmte Zeit geschlossen und gilt bis zur Beendigung des PATHADVICE Lizenzvertrages."
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "mt-12" }, [
                      _c("strong", [_vm._v("3. Pflichten der PA")]),
                      _c("ol", { staticClass: "number mt-2 mb-5" }, [
                        _c("li", [
                          _vm._v(
                            "\n                  PA verpflichtet sich, Daten und Verarbeitungsergebnisse ausschließlich im Rahmen der schriftlichen Aufträge des PARTNERS zu verarbeiten.\n                  Erhält PA einen behördlichen Auftrag, Daten des PARTNER herauszugeben, so hat er - sofern gesetzlich zulässig - den PARTNER unverzüglich\n                  darüber zu informieren und die Behörde an diesen zu verweisen.\n                "
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            "\n                  PA wird die Daten für eigene Zwecke ausschließlich anonymisiert und auf statistischer Basis zur Durchführung sowie zur Verbesserung der\n                  PA-Services verarbeiten.\n                "
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            "\n                  PA erklärt rechtsverbindlich, dass alle mit der Datenverarbeitung beauftragten Personen vor Aufnahme der Tätigkeit zur Vertraulichkeit\n                  verpflichtet hat oder diese einer angemessenen gesetzlichen Verschwiegenheitsverpflichtung unterliegen. Insbesondere bleibt die\n                  Verschwiegenheitsverpflichtung der mit der Datenverarbeitung beauftragten Personen auch nach Beendigung ihrer Tätigkeit und Ausscheiden beim\n                  PA aufrecht.\n                "
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            "\n                  PA erklärt rechtsverbindlich, dass sie alle erforderlichen Maßnahmen zur Gewährleistung der Sicherheit der Verarbeitung nach Art 32 DSGVO\n                  ergriffen hat (Einzelheiten sind der Anlage ./1 zu entnehmen).\n                "
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            "\n                  PA ergreift die technischen und organisatorischen Maßnahmen, damit der PARTNER die Rechte der betroffenen Person nach Kapitel III der DSGVO\n                  (Information, Auskunft, Berichtigung und Löschung, Datenübertragbarkeit, Widerspruch, sowie automatisierte Entscheidungsfindung im\n                  Einzelfall) innerhalb der gesetzlichen Fristen jederzeit erfüllen kann und überlässt dem PARTNER alle dafür notwendigen Informationen. Wird\n                  ein entsprechender Antrag an PA gerichtet und lässt dieser Antrag erkennen, dass der Antragsteller PA irrtümlich für den Verantwortlichen\n                  der von ihm betriebenen Datenverarbeitung hält, hat PA den Antrag unverzüglich an den PARTNER weiterzuleiten und dies dem Antragsteller\n                  mitzuteilen.\n                "
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            "\n                  PA unterstützt den PARTNER bei der Einhaltung der in den Art 32 bis 36 DSGVO genannten Pflichten (Datensicherheitsmaßnahmen, Meldungen von\n                  Verletzungen des Schutzes personenbezogener Daten an die Aufsichtsbehörde, Benachrichtigung der von einer Verletzung des Schutzes\n                  personenbezogener Daten betroffenen Person, Datenschutz-Folgeabschätzung, vorherige Konsultation).\n                "
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            "\n                  Der PARTNER wird darauf hingewiesen, dass er für die vorliegende Auftragsverarbeitung ein Verarbeitungsverzeichnis nach Art 30 DSGVO zu\n                  errichten hat.\n                "
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            "\n                  Dem PARTNER wird hinsichtlich der Verarbeitung der von ihm überlassenen Daten das Recht jederzeitiger Einsichtnahme und Kontrolle, sei es\n                  auch durch von ihm beauftragte Dritte, der Datenverarbeitungseinrichtungen eingeräumt. PA verpflichtet sich, dem PARTNER jene Informationen\n                  zur Verfügung zu stellen, die zur Kontrolle der Einhaltung der in dieser Vereinbarung genannten Verpflichtungen notwendig sind.\n                "
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            "\n                  PA ist nach Beendigung dieser Vereinbarung verpflichtet, alle Verarbeitungsergebnisse und Unterlagen, die Daten enthalten im Auftrag des\n                  PARTNERS zu vernichten. Wenn PA die Daten in einem speziellen technischen Format verarbeitet, ist PA verpflichtet, die Daten nach Beendigung\n                  dieser Vereinbarung in diesem Format oder in einem anderen, gängigen Format herauszugeben.\n                "
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            "\n                  PA hat den PARTNER unverzüglich zu informieren, falls er der Ansicht ist, eine Weisung des PARTNER verstößt gegen Datenschutzbestimmungen\n                  der Union oder der Mitgliedstaaten.\n                "
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "mt-12" }, [
                      _c("strong", [_vm._v("4. Pflichten des PARTNERS")]),
                      _c("p", [
                        _vm._v(
                          "\n                Für die Beurteilung der Zulässigkeit der beauftragten Verarbeitung sowie für die Wahrung der Rechte von Betroffenen ist allein der PARTNER\n                verantwortlich.\n              "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "mt-12" }, [
                      _c("strong", [
                        _vm._v("5. Technische und organisatorische Maßnahmen"),
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n                Die Datensicherheitsmaßnahmen sind aus Anlage 1 ersichtlich. Datensicherheitsmaßnahmen können der technischen und organisatorischen\n                Weiterentwicklung entsprechend angepasst werden, solange das hier vereinbarte Niveau nicht unterschritten wird.\n              "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "mt-12" }, [
                      _c("strong", [
                        _vm._v("6. Ort der Durchführung der Datenverarbeitung"),
                      ]),
                      _c("p", [
                        _vm._v(
                          "Alle Datenverarbeitungstätigkeiten werden innerhalb der Europäischen Union durchgeführt."
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "mt-12" }, [
                      _c("strong", [_vm._v("7. Sub-Auftragsverarbeiter")]),
                      _c("p", [
                        _vm._v(
                          "\n                PA arbeitet abhängig von den zur Verfügung gestellten Services und deren Ausprägung mit verschiedener Sub-Auftragsverarbeiter zusammen. Eine\n                Liste der Sub-Auftragsverarbeiter wird auf Anfrage an "
                        ),
                        _c(
                          "a",
                          { attrs: { href: "mailto:support@pathadvice.at" } },
                          [_vm._v("support@pathadvice.at")]
                        ),
                        _vm._v(" zur Verfügung gestellt.\n              "),
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n                PA kann weitere Sub-Auftragsverarbeiter hinzuziehen. PA hat den PARTNER von der beabsichtigten Heranziehung eines Sub-Auftragsverarbeiters so\n                rechtzeitig zu verständigen, dass der PARTNER dies allenfalls untersagen kann. PA schließt die erforderlichen Vereinbarungen im Sinne des Art\n                28 Abs 4 DSGVO mit dem Sub-Auftragsverarbeiter ab. Dabei ist sicherzustellen, dass der Sub-Auftragsverarbeiter dieselben Verpflichtungen\n                eingeht, die PA auf Grund dieser Vereinbarung obliegen. Kommt der Sub-Auftragsverarbeiter seinen Datenschutzpflichten nicht nach, so haftet PA\n                gegenüber dem PARTNER für die Einhaltung der Pflichten des Sub-Auftragsverarbeiters.\n              "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "mt-12" }, [
                      _c("strong", [_vm._v("8. Beendigung ")]),
                      _c("p", [
                        _vm._v(
                          "\n                Bei Beendigung des Vertragsverhältnisses oder jederzeit auf Verlangen des PARTNERS hat PA die im Auftrag verarbeiteten Daten zu vernichten.\n                Die Vernichtung hat so zu erfolgen, dass eine Wiederherstellung auch von Restinformationen mit vertretbarem Aufwand nicht mehr möglich ist.\n              "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n                Davon unbeschränkt bleibt das Recht der PA, diese Daten auch nach der Beendigung in anonymisierter Form und auf statistischer Basis zur\n                Verbesserung seiner Services zu nutzen.\n              "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "mt-12" }, [
                      _c("strong", [_vm._v("9. Sonstiges ")]),
                      _c("p", [
                        _vm._v(
                          "\n                Beide Parteien sind verpflichtet, alle im Rahmen des Vertragsverhältnisses erlangten Kenntnisse von Geschäftsgeheimnissen und\n                Datensicherheitsmaßnahmen der jeweils anderen Partei auch über die Beendigung des Vertrages vertraulich zu behandeln. Bestehen Zweifel, ob\n                eine Information der Geheimhaltungspflicht unterliegt, ist sie bis zur schriftlichen Freigabe durch die andere Partei als vertraulich zu\n                behandeln.\n              "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "mt-12" }, [
                      _c("strong", [_vm._v("Anlage ./1 ")]),
                      _c("br"),
                      _c("strong", [
                        _vm._v(
                          "Technische und organisatorische Maßnahmen i.S.d. Art. 32 DSGVO "
                        ),
                      ]),
                      _c("br"),
                      _c("div", { staticClass: "mt-6" }, [
                        _c("strong", [_vm._v("A. VERTRAULICHKEIT")]),
                      ]),
                      _c("div", { staticClass: "mt-2" }, [
                        _c("strong", [_vm._v("Zutrittskontrolle:")]),
                        _vm._v(
                          "Schutz vor unbefugtem Zutritt zu Datenverarbeitungsanlagen durch:"
                        ),
                      ]),
                      _c("table", [
                        _c("tr", [
                          _c("td", [_vm._v("X Schlüssel")]),
                          _c("td", [_vm._v("x Magnet- oder Chipkarten")]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("☐ Elektrische Türöffner")]),
                          _c("td", [_vm._v("☐ Portier")]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("☐ Sicherheitspersonal")]),
                          _c("td", [_vm._v("x Alarmanlagen")]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("☐ Videoanlage")]),
                          _c("td", [
                            _vm._v(
                              "x Einbruchshemmende Fenster und/oder Sicherheitstüren"
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [
                            _vm._v(
                              "x Anmeldung beim Empfang mit Personenkontrolle"
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              "x Begleitung von Besuchern im Unternehmensgebäude"
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [
                            _vm._v("X Tragen von Firmen-/Besucherausweisen"),
                          ]),
                          _c("td", [_vm._v("☐ Sonstiges:")]),
                        ]),
                      ]),
                      _c("div", { staticClass: "mt-2" }, [
                        _c("strong", [_vm._v("Zugangskontrolle:")]),
                        _vm._v("Schutz vor unbefugter Systembenutzung durch:"),
                      ]),
                      _c("table", [
                        _c("tr", [
                          _c("td", [
                            _vm._v(
                              "x Kennwörter (einschließlich entsprechender Policy)"
                            ),
                          ]),
                          _c("td", [
                            _vm._v("x Verschlüsselung von Datenträgern"),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("☐ Automatische Sperrmechanismen")]),
                          _c("td", [_vm._v("☐ Sonstiges:")]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("x Zwei-Faktor-Authentifizierung")]),
                          _c("td"),
                        ]),
                      ]),
                      _c("div", { staticClass: "mt-2" }, [
                        _c("strong", [_vm._v("Zugriffskontrolle:")]),
                        _vm._v(
                          "Kein unbefugtes Lesen, Kopieren, Verändern oder Entfernen innerhalb des Systems durch:\n              "
                        ),
                      ]),
                      _c("table", [
                        _c("tr", [
                          _c("td", [
                            _vm._v(
                              "X Standard-Berechtigungsprofile auf „need to know-Basis“"
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              "X Standardprozess für Berechtigungsvergabe"
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("X Protokollierung von Zugriffen")]),
                          _c("td", [
                            _vm._v("X Sichere Aufbewahrung von Speichermedien"),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [
                            _vm._v(
                              "x Periodische Überprüfung der vergebenen Berechtigungen, insb von administrativen Benutzerkonten"
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              "X Datenschutzgerechte Wiederverwendung von Datenträgern"
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [
                            _vm._v(
                              "X Datenschutzgerechte Entsorgung nicht mehr benötigter Datenträger"
                            ),
                          ]),
                          _c("td", [
                            _vm._v("X Clear-Desk/Clear-Screen Policy"),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("☐ Sonstiges:")]),
                          _c("td"),
                        ]),
                      ]),
                      _c("div", { staticClass: "mt-2" }, [
                        _c("strong", [_vm._v("Pseudonymisierung:")]),
                        _vm._v(
                          "Sofern für die jeweilige Datenverarbeitung möglich, werden die primären Identifikationsmerkmale der\n                personenbezogenen Daten in der jeweiligen Datenverarbeitung entfernt, und gesondert aufbewahrt.\n              "
                        ),
                      ]),
                      _c("table", [
                        _c("tr", [
                          _c("td", [_vm._v("X Ja")]),
                          _c("td", [_vm._v("☐ Nein")]),
                        ]),
                      ]),
                      _c("div", { staticClass: "mt-2" }, [
                        _c("strong", [
                          _vm._v("Klassifikationsschema für Daten:"),
                        ]),
                        _vm._v(
                          "Sofern für die jeweilige Datenverarbeitung möglich, werden die primären\n                Identifikationsmerkmale der personenbezogenen Daten in der jeweiligen Datenverarbeitung entfernt, und gesondert aufbewahrt.\n              "
                        ),
                      ]),
                      _c("table", [
                        _c("tr", [
                          _c("td", [_vm._v("X Ja")]),
                          _c("td", [_vm._v("☐ Nein")]),
                        ]),
                      ]),
                      _c("div", { staticClass: "mt-6" }, [
                        _c("strong", [_vm._v("B. DATENINTEGRITÄT")]),
                      ]),
                      _c("div", { staticClass: "mt-2" }, [
                        _c("strong", [_vm._v("Weitergabekontrolle: ")]),
                        _vm._v(
                          "Kein unbefugtes Lesen, Kopieren, Verändern oder Entfernen bei elektronischer Übertragung oder Transport\n                durch:\n              "
                        ),
                      ]),
                      _c("table", [
                        _c("tr", [
                          _c("td", [
                            _vm._v("X Verschlüsselung von Datenträgern"),
                          ]),
                          _c("td", [_vm._v("X Verschlüsselung von Dateien")]),
                        ]),
                        _c("tr", [
                          _c("td", [
                            _vm._v("☐ Virtual Private Networks (VPN)"),
                          ]),
                          _c("td", [_vm._v("☐ Elektronische Signatur")]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("☐ Sonstiges:")]),
                          _c("td"),
                        ]),
                      ]),
                      _c("div", { staticClass: "mt-2" }, [
                        _c("strong", [_vm._v("Eingabekontrolle: ")]),
                        _vm._v(
                          "Feststellung, ob und von wem personenbezogene Daten in Datenverarbeitungssysteme eingegeben, verändert oder\n                entfernt worden sind durch:\n              "
                        ),
                      ]),
                      _c("table", [
                        _c("tr", [
                          _c("td", [_vm._v("X Protokollierung")]),
                          _c("td", [_vm._v("X Dokumentenmanagement")]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("☐ Sonstiges:")]),
                          _c("td"),
                        ]),
                      ]),
                      _c("div", { staticClass: "mt-6" }, [
                        _c("strong", [
                          _vm._v("C. VERFÜGBARKEIT UND BELASTBARKEIT"),
                        ]),
                      ]),
                      _c("div", { staticClass: "mt-2" }, [
                        _c("strong", [_vm._v("Verfügbarkeitskontrolle: ")]),
                        _vm._v(
                          "Schutz gegen zufällige oder mutwillige Zerstörung bzw. Verlust durch:"
                        ),
                      ]),
                      _c("table", [
                        _c("tr", [
                          _c("td", [
                            _vm._v(
                              "X Backup-Strategie (online/offline; on-site/off-site)"
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              "☐ Unterbrechungsfreie Stromversorgung (USV, Dieselaggregat)"
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("X Virenschutz")]),
                          _c("td", [_vm._v("X Firewall")]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("X Meldewege und Notfallpläne")]),
                          _c("td", [
                            _vm._v(
                              "X Security Checks auf Infrastruktur- und Applikationsebene"
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [
                            _vm._v(
                              "☐ Mehrstufiges Sicherungskonzept mit verschlüsselter Auslagerung der Sicherungen in ein Ausweichrechenzentrum"
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              "X Standardprozesse bei Wechsel/Ausscheiden von Mitarbeitern"
                            ),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("☐ Sonstiges:")]),
                          _c("td"),
                        ]),
                      ]),
                      _c("div", { staticClass: "mt-2" }, [
                        _c("strong", [_vm._v("Rasche Wiederherstellbarkeit:")]),
                      ]),
                      _c("table", [
                        _c("tr", [
                          _c("td", [_vm._v("X Ja")]),
                          _c("td", [_vm._v("☐ Nein")]),
                        ]),
                      ]),
                      _c("div", { staticClass: "mt-6" }, [
                        _c("strong", [
                          _vm._v(
                            "D. VERFAHREN ZUR REGELMÄßIGEN ÜBERPRÜFUNG, BEWERTUNG UND EVALUIERUNG"
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "mt-2" }, [
                        _c("strong", [_vm._v("Datenschutz-Management, ")]),
                        _vm._v(
                          "einschließlich regelmäßiger Mitarbeiter-Schulungen:"
                        ),
                      ]),
                      _c("table", [
                        _c("tr", [
                          _c("td", [_vm._v("X Ja")]),
                          _c("td", [_vm._v("☐ Nein")]),
                        ]),
                      ]),
                      _c("div", { staticClass: "mt-2" }, [
                        _c("strong", [_vm._v("Incident-Response-Management:")]),
                      ]),
                      _c("table", [
                        _c("tr", [
                          _c("td", [_vm._v("X Ja")]),
                          _c("td", [_vm._v("☐ Nein")]),
                        ]),
                      ]),
                      _c("div", { staticClass: "mt-2" }, [
                        _c("strong", [
                          _vm._v("Datenschutzfreundliche Voreinstellungen:"),
                        ]),
                      ]),
                      _c("table", [
                        _c("tr", [
                          _c("td", [_vm._v("X Ja")]),
                          _c("td", [_vm._v("☐ Nein")]),
                        ]),
                      ]),
                      _c("div", { staticClass: "mt-2" }, [
                        _c("strong", [_vm._v("Auftragskontrolle: ")]),
                        _vm._v(
                          "Keine Auftragsdatenverarbeitung im Sinne von Art 28 DS-GVO ohne entsprechende Weisung des PARTNER durch:\n              "
                        ),
                      ]),
                      _c("table", [
                        _c("tr", [
                          _c("td", [_vm._v("X Eindeutige Vertragsgestaltung")]),
                          _c("td", [
                            _vm._v("X Formalisiertes Auftragsmanagement"),
                          ]),
                        ]),
                        _c("tr", [
                          _c("td", [
                            _vm._v(
                              "X Strenge Auswahl des Auftragsverarbeiters (ISO-Zertifizierung, ISMS)"
                            ),
                          ]),
                          _c("td", [_vm._v("☐ Vorabüberzeugungspflicht")]),
                        ]),
                        _c("tr", [
                          _c("td", [_vm._v("☐ Nachkontrollen")]),
                          _c("td", [_vm._v("☐ Sonstiges:")]),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "mt-12" }, [
                      _c("strong", [
                        _vm._v("Anlage ./2 – Sub-Auftragsverarbeiter "),
                      ]),
                      _c("p", [
                        _vm._v(
                          "Google Firebase (europe-west3), domiciled in Frankfurt/Germany; Contractual relationship with Google Irland;"
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "Hosting services: Surfly BV, Singel 373, 1012 WL Amsterdam/ Netherlands: API für Co-Browsing"
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "mt-12" }, [
                      _c("strong", [_vm._v("Anlage 5 / Preisblatt")]),
                      _c("br"),
                      _c("strong", [_vm._v("PATHADVICE Connect")]),
                      _c("br"),
                      _c("p", [
                        _vm._v(
                          "Für die in Anlage 1 dargestellten Services verrechnet PA Lizenzgebühren:"
                        ),
                      ]),
                      _c("p", [
                        _c("strong", [
                          _c("u", [_vm._v("49,00 zzgl. MWSt. / Monat ")]),
                        ]),
                      ]),
                      _c("p", [_vm._v("oder")]),
                      _c("p", [
                        _c("strong", [
                          _c("u", [_vm._v("540,00 zzgl. MWSt. / Jahr")]),
                        ]),
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n                Lizenzgebühren werden mit Zeichnung der Lizenzvereinbarung in Rechnung gestellt und fällig sowie abhängig vom gewählten Zahlungsmittel und der\n                gewählten Zahlungsart zum Beginn der Laufzeit monatlich bzw. jährlich eingezogen.\n              "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n                Im Falle, dass der PARTNER während der Laufzeit dieser Vereinbarung zusätzliche Services ordert, werden die Kosten der zusätzlich georderten\n                Services/Produkte ab dem Folgemonat in Rechnung gestellt.\n              "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "mt-12" }, [
                      _c("strong", [_vm._v("Anlage 6 / Kontaktdaten")]),
                      _c("br"),
                      _c("p", { staticClass: "mt-2 mb-5" }, [
                        _vm._v("\n                Email: "),
                        _c(
                          "a",
                          { attrs: { href: "mailto:support@pathadvice.at" } },
                          [_vm._v("support@pathadvice.at")]
                        ),
                        _c("br"),
                        _vm._v("\n                Chat: Im Login Bereich"),
                        _c("br"),
                        _vm._v(
                          "\n                Postalisch: PATHADVICE International GmbH, Serlesweg 3, 6161 Natters, Austria\n              "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }