var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.company && _vm.users
    ? _c(
        "div",
        {
          staticClass: "data-list-container",
          attrs: { id: "data-list-list-view" },
        },
        [
          _c("license-agreement", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showLicenseAgreement,
                expression: "showLicenseAgreement",
              },
            ],
          }),
          _c("pricing-plans", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showPriceList,
                expression: "showPriceList",
              },
            ],
          }),
          _c("link", {
            attrs: {
              rel: "stylesheet",
              type: "text/css",
              media: "all",
              href: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.min.css",
            },
          }),
          _c(
            "vs-prompt",
            {
              attrs: {
                title: _vm.$t("modals.deleteTeamMember"),
                color: "danger",
                "cancel-text": _vm.$t("vue.cancel"),
                "accept-text": _vm.$t("vue.delete"),
                active: _vm.showConfirmDelete,
              },
              on: {
                cancel: _vm.onCloseModal,
                accept: _vm.deleteData,
                close: _vm.onCloseModal,
                "update:active": function ($event) {
                  _vm.showConfirmDelete = $event
                },
              },
            },
            [
              _vm.selectedUser
                ? _c("div", { staticClass: "con-exemple-prompt" }, [
                    _c("p", [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.selectedUser.displayName
                              ? _vm.selectedUser.displayName
                              : _vm.selectedUser.firstname +
                                  " " +
                                  _vm.selectedUser.lastname
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("modals.deleteTeamMemberQuestion")) +
                        "\n    "
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "vx-card",
            {
              staticClass: "mb-4",
              attrs: {
                title: _vm.$t("routes.users"),
                "title-color": "primary",
                "subtitle-color": "danger",
              },
            },
            [
              _c("p", { staticClass: "mb-3" }, [
                _vm._v(_vm._s(_vm.$t("vue.companyTeamTabDescription"))),
              ]),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _vm.SHOW_AVAILABLE_REMAINING_USERS
                    ? _c("vs-row", { attrs: { "vs-justify": "flex-start" } }, [
                        _c("div", [
                          _c("span", { staticClass: "mr-2" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("vue.totalAvailableUsers")) +
                                " : "
                            ),
                            _c("b", [_vm._v(_vm._s(_vm.totalAvailableUsers))]),
                          ]),
                          _c("span", { staticClass: "ml-2" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("vue.totalRemainingUsers")) +
                                " : "
                            ),
                            _c("b", [_vm._v(_vm._s(_vm.totalRemainingUsers))]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  !_vm.activeUserInfo.role ||
                  _vm.activeUserInfo.role === "admin"
                    ? _c(
                        "vs-row",
                        { attrs: { "vs-justify": "flex-end" } },
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass: "flex",
                              attrs: {
                                color: "primary",
                                type: "filled",
                                "icon-size": "24px",
                                icon: "add",
                                disabled: _vm.DISABLE_ADDING_NEW_TEAM_MEMBER,
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.inviteUser.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("vue.inviteUser")))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c(
            "vx-card",
            [
              _c(
                "vs-table",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.showLicenseAgreement && !_vm.showPriceList,
                      expression: "!showLicenseAgreement && !showPriceList",
                    },
                  ],
                  ref: "table",
                  attrs: {
                    pagination: "",
                    "max-items": _vm.itemsPerPage,
                    search: "",
                    data: _vm.sortedViewedUsers,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var data = ref.data
                          return [
                            _c(
                              "tbody",
                              _vm._l(data, function (tr, indextr) {
                                return _c(
                                  "vs-tr",
                                  { key: indextr, attrs: { data: tr } },
                                  [
                                    _c(
                                      "vs-td",
                                      [
                                        _c("Avatar", {
                                          staticClass: "mr-1",
                                          attrs: {
                                            size: "38px",
                                            photoURL: tr.photoURL,
                                            name:
                                              tr.firstname + " " + tr.lastname,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("vs-td", [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "product-name font-medium truncate",
                                        },
                                        [_vm._v(_vm._s(tr.email))]
                                      ),
                                    ]),
                                    _c("vs-td", [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "product-name font-medium truncate",
                                        },
                                        [_vm._v(_vm._s(tr.firstname))]
                                      ),
                                    ]),
                                    _c("vs-td", [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "product-name font-medium truncate",
                                        },
                                        [_vm._v(_vm._s(tr.lastname))]
                                      ),
                                    ]),
                                    !_vm.activeUserInfo.role ||
                                    _vm.activeUserInfo.role === "admin" ||
                                    _vm.activeUserInfo.impersonated
                                      ? _c("vs-td", [
                                          tr.id !== _vm.activeUserInfo.uid ||
                                          _vm.activeUserInfo.impersonated
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "product-name font-medium truncate userRole",
                                                },
                                                [
                                                  _c(
                                                    "select",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          (_vm.disable ||
                                                          tr.emailPending ||
                                                          tr.id ===
                                                            _vm.company
                                                              .createdBy
                                                            ? true
                                                            : false) &&
                                                          !_vm.activeUserInfo
                                                            .impersonated,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.updateRole(
                                                            tr.id,
                                                            $event
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm.activeUserInfo
                                                        .impersonated
                                                        ? _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  "vega-analyze",
                                                              },
                                                              domProps: {
                                                                selected:
                                                                  tr.userRole ===
                                                                  "vega-analyze",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                    " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "vue.vegaAnalyze"
                                                                    )
                                                                  ) +
                                                                  "\n                  "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "admin",
                                                          },
                                                          domProps: {
                                                            selected:
                                                              tr.userRole ===
                                                              "admin",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "vue.admin"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "agent",
                                                          },
                                                          domProps: {
                                                            selected:
                                                              tr.userRole ===
                                                              "agent",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "vue.agent"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "view-only",
                                                          },
                                                          domProps: {
                                                            selected:
                                                              tr.userRole ===
                                                              "view-only",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "vue.viewOnly"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.company.isCallCenter
                                                        ? _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value:
                                                                  "supervisor",
                                                              },
                                                              domProps: {
                                                                selected:
                                                                  tr.userRole ===
                                                                  "supervisor",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "vue.supervisor"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                    (!_vm.activeUserInfo.role ||
                                      _vm.activeUserInfo.role === "admin") &&
                                    _vm.company.isCallCenter
                                      ? _c("vs-td", [
                                          tr.id !== _vm.activeUserInfo.uid &&
                                          tr.userRole === "agent" &&
                                          _vm.supervisorsList.length &&
                                          !tr.emailPending
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "product-name font-medium truncate userRole",
                                                },
                                                [
                                                  _c(
                                                    "select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            tr.supervisorId,
                                                          expression:
                                                            "tr.supervisorId",
                                                        },
                                                      ],
                                                      on: {
                                                        change: [
                                                          function ($event) {
                                                            var $$selectedVal =
                                                              Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function (o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function (
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                            _vm.$set(
                                                              tr,
                                                              "supervisorId",
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                            )
                                                          },
                                                          function ($event) {
                                                            return _vm.setSupervisor(
                                                              $event,
                                                              tr.id
                                                            )
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    [
                                                      !tr.supervisorId
                                                        ? _c("option", {
                                                            attrs: {
                                                              disabled: "",
                                                              selected: "",
                                                              value: "",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _vm._l(
                                                        _vm.supervisorsList,
                                                        function (supervisor) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              key: supervisor.id,
                                                              domProps: {
                                                                value:
                                                                  supervisor.id,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  supervisor.displayName
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                    _vm.company.isCallCenter &&
                                    ["agent", "supervisor"].includes(
                                      _vm.activeUserInfo.role
                                    )
                                      ? _c("vs-td", [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "product-name font-medium truncate userRole",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("vue." + tr.userRole)
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c("vs-td", [
                                      _c(
                                        "div",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.onToggleActiveConsultant(
                                                tr
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("toggle-switch-icon", {
                                            attrs: {
                                              opacity:
                                                tr.userRole === "admin"
                                                  ? 1
                                                  : 0.4,
                                              enabled: tr.isActiveConsultant,
                                              width: 40,
                                              height: 40,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "vs-td",
                                      { staticClass: "whitespace-no-wrap" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex-column justify-center items-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex justify-center items-center",
                                              },
                                              [
                                                tr.id !==
                                                  _vm.activeUserInfo.uid &&
                                                (tr.emailPending ||
                                                  ((typeof tr.provider ===
                                                    "undefined" ||
                                                    tr.provider === "email") &&
                                                    !tr.hasSetPassword))
                                                  ? _c(
                                                      "vs-button",
                                                      {
                                                        staticClass:
                                                          "table-button",
                                                        attrs: {
                                                          disabled:
                                                            _vm.disable ||
                                                            _vm.disableResendEmailInvitation
                                                              ? true
                                                              : false,
                                                          color: "warning",
                                                          type: "border",
                                                          size: "medium",
                                                          "icon-pack":
                                                            "feather",
                                                          icon: "icon-rotate-cw",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.resendEmailInvitation(
                                                              tr
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "vue.resendEmailInvitation"
                                                              )
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                tr.id === _vm.activeUserInfo.uid
                                                  ? _c(
                                                      "vs-button",
                                                      {
                                                        staticClass:
                                                          "table-button",
                                                        attrs: {
                                                          color: "primary",
                                                          type: "border",
                                                          size: "medium",
                                                          "icon-pack":
                                                            "feather",
                                                          icon: "icon-edit",
                                                          to: "/user-settings",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "vue.myAccount"
                                                              )
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "vx-tooltip",
                                                  {
                                                    attrs: {
                                                      text: _vm.$t(
                                                        "vue.impersonate"
                                                      ),
                                                      position: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm.activeUserInfo.uid !==
                                                      tr.id &&
                                                    _vm.activeUserInfo
                                                      .impersonated &&
                                                    !tr.emailPending
                                                      ? _c("vs-button", {
                                                          attrs: {
                                                            color: "primary",
                                                            type: "border",
                                                            size: "medium",
                                                            "icon-pack":
                                                              "feather",
                                                            icon: "icon-user",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.impersonate(
                                                                tr.id
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  [
                                                    tr.id ===
                                                      _vm.company.createdBy &&
                                                    tr.id !==
                                                      _vm.activeUserInfo.uid
                                                      ? _c(
                                                          "vs-chip",
                                                          {
                                                            class: {
                                                              "ml-2":
                                                                _vm
                                                                  .activeUserInfo
                                                                  .impersonated,
                                                              "ml-auto":
                                                                !_vm
                                                                  .activeUserInfo
                                                                  .impersonated,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      owner\n                    "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    tr.id !==
                                                      _vm.activeUserInfo.uid &&
                                                    tr.id !==
                                                      _vm.company.createdBy &&
                                                    (!_vm.activeUserInfo.role ||
                                                      _vm.activeUserInfo
                                                        .role === "admin")
                                                      ? _c(
                                                          "vx-tooltip",
                                                          {
                                                            staticClass:
                                                              "inline-flex",
                                                            attrs: {
                                                              text: _vm.$t(
                                                                "vue.delete"
                                                              ),
                                                              position: "top",
                                                            },
                                                          },
                                                          [
                                                            _c("vs-button", {
                                                              attrs: {
                                                                color: "danger",
                                                                type: "border",
                                                                size: "medium",
                                                                "icon-pack":
                                                                  "feather",
                                                                icon: "icon-trash",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return function () {
                                                                      return _vm.onDeleteData(
                                                                        tr
                                                                      )
                                                                    }.apply(
                                                                      null,
                                                                      arguments
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            tr.inviteStatus && tr.emailPending
                                              ? _c(
                                                  "vs-chip",
                                                  {
                                                    staticClass: "mt-2",
                                                    attrs: {
                                                      color:
                                                        _vm.deliveryStatusColor(
                                                          tr.inviteStatus
                                                        ),
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            tr.inviteStatus
                                                          )
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }),
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    155576492
                  ),
                  model: {
                    value: _vm.selected,
                    callback: function ($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-wrap-reverse items-center flex-grow justify-end",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c(
                        "vs-dropdown",
                        {
                          staticClass:
                            "cursor-pointer mb-4 mr-4 items-per-page-handler",
                          attrs: { "vs-trigger-click": "" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "p-4 border border-solid d-theme-border-grey-light d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium",
                              staticStyle: { "border-radius": "5px" },
                            },
                            [
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.firstRecordNumber) +
                                    " - " +
                                    _vm._s(_vm.lastRecordNumber) +
                                    " " +
                                    _vm._s(_vm.$t("vue.of")) +
                                    "\n              " +
                                    _vm._s(_vm.queriedItems) +
                                    "\n            "
                                ),
                              ]),
                              _c("feather-icon", {
                                attrs: {
                                  icon: "ChevronDownIcon",
                                  svgClasses: "h-4 w-4",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "vs-dropdown-menu",
                            [
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.itemsPerPage = 3
                                    },
                                  },
                                },
                                [_c("span", [_vm._v("3")])]
                              ),
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.itemsPerPage = 4
                                    },
                                  },
                                },
                                [_c("span", [_vm._v("4")])]
                              ),
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.itemsPerPage = 10
                                    },
                                  },
                                },
                                [_c("span", [_vm._v("10")])]
                              ),
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.itemsPerPage = 15
                                    },
                                  },
                                },
                                [_c("span", [_vm._v("15")])]
                              ),
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.itemsPerPage = 20
                                    },
                                  },
                                },
                                [_c("span", [_vm._v("20")])]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", { attrs: { "sort-key": "user" } }),
                      _c("vs-th", { attrs: { "sort-key": "email" } }, [
                        _vm._v(_vm._s(_vm.$t("vue.email"))),
                      ]),
                      _c("vs-th", { attrs: { "sort-key": "firstname" } }, [
                        _vm._v(_vm._s(_vm.$t("vue.firstname"))),
                      ]),
                      _c("vs-th", { attrs: { "sort-key": "lastname" } }, [
                        _vm._v(_vm._s(_vm.$t("vue.lastname"))),
                      ]),
                      !_vm.activeUserInfo.role ||
                      _vm.activeUserInfo.role === "admin" ||
                      (_vm.company.isCallCenter &&
                        ["agent", "supervisor"].includes(
                          _vm.activeUserInfo.role
                        ))
                        ? _c("vs-th", { attrs: { "sort-key": "lastname" } }, [
                            _vm._v("User Role"),
                          ])
                        : _vm._e(),
                      (!_vm.activeUserInfo.role ||
                        _vm.activeUserInfo.role === "admin") &&
                      _vm.company.isCallCenter
                        ? _c("vs-th", [_vm._v("User supervisor")])
                        : _vm._e(),
                      _c("vs-th", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("vue.activeConsultant")) + " "
                        ),
                      ]),
                      _c("vs-th", { staticStyle: { width: "300px" } }, [
                        _vm._v(_vm._s(_vm.$t("vue.action"))),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm.activePrompt
                ? _c(
                    "vs-prompt",
                    {
                      attrs: {
                        "cancel-text": _vm.$t("vue.cancel"),
                        "accept-text": _vm.$t("vue.inviteUser"),
                        title: _vm.$t("vue.inviteUser"),
                        active: _vm.activePrompt,
                        "is-valid": _vm.validateForm,
                      },
                      on: {
                        accept: _vm.createUserEmail,
                        "update:active": function ($event) {
                          _vm.activePrompt = $event
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "con-exemple-prompt" },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("vue.enterFirstname"))),
                          ]),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "mt-3 w-full",
                            attrs: {
                              placeholder: _vm.$t("vue.firstname"),
                              "data-vv-validate-on": "blur",
                              name: _vm.$t("vue.firstname"),
                            },
                            model: {
                              value: _vm.inviteData.firstname,
                              callback: function ($$v) {
                                _vm.$set(_vm.inviteData, "firstname", $$v)
                              },
                              expression: "inviteData.firstname",
                            },
                          }),
                          _c("div", { staticClass: "text-danger text-sm" }, [
                            _vm._v(
                              _vm._s(_vm.errors.first(_vm.$t("vue.firstname")))
                            ),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("vue.enterLastname"))),
                          ]),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "mt-3 w-full",
                            attrs: {
                              placeholder: _vm.$t("vue.lastname"),
                              "data-vv-validate-on": "blur",
                              name: _vm.$t("vue.lastname"),
                            },
                            model: {
                              value: _vm.inviteData.lastname,
                              callback: function ($$v) {
                                _vm.$set(_vm.inviteData, "lastname", $$v)
                              },
                              expression: "inviteData.lastname",
                            },
                          }),
                          _c("div", { staticClass: "text-danger text-sm" }, [
                            _vm._v(
                              _vm._s(_vm.errors.first(_vm.$t("vue.lastname")))
                            ),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("vue.enterEmailAddress"))),
                          ]),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|email",
                                expression: "'required|email'",
                              },
                            ],
                            staticClass: "mt-3 w-full",
                            attrs: {
                              placeholder: _vm.$t("vue.email"),
                              "data-vv-validate-on": "blur",
                              name: _vm.$t("inputs.email"),
                              type: "email",
                            },
                            model: {
                              value: _vm.inviteData.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.inviteData, "email", $$v)
                              },
                              expression: "inviteData.email",
                            },
                          }),
                          _c("div", { staticClass: "text-danger text-sm" }, [
                            _vm._v(
                              _vm._s(_vm.errors.first(_vm.$t("inputs.email")))
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }