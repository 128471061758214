<template>
  <transition name="fade-bottom-2x">
    <div>
      <div class="flex vx-col w-full lg:w-1/1 sm:w-1/1 mb-base">
        <vx-card>
          <div class="pt-2 pb-2 pr-2 pl-2" align="right">
            <vs-button color="secondary" type="filled" icon-pack="feather" icon="icon-x" @click="closeLicenseAgreement()"></vs-button>
            <vs-row style="padding: 5px">
              <vs-col class="text-left" vs-type="flex" vs-justify="lwft" vs-align="left" vs-w="6">
                <div class="agreement-header-left vx-logo cursor-pointer flex items-center router-link-active">
                  <img src="/logo.svg" width="60" height="60" class="mr-4 fill-current text-secondary" />
                  <span class="text-secondary">PATHADVICE</span>
                </div>
              </vs-col>
              <vs-col class="text-right" vs-type="block" vs-justify="right" vs-align="right" vs-w="6">
                Lizenzvereinbarung <br />
                PATHADVICE CONNECT <br />
                A3100D
              </vs-col>
            </vs-row>
            <div class="agreement-text mt-5 text-left justify">
              <p class="mt-5">zwischen der</p>
              <p class="mt-5">
                PATHADVICE International GmbH<br />
                Serlesweg 3<br />
                6161 Natters<br />
                Austria<br />
                (nachfolgend „PA“ genannt)<br />
              </p>
              <p class="mt-5 mb-5">
                und dem PARTNER nachfolgend „PARTNER“ genannt<br />
                gemeinsam nachfolgend „PARTEIEN“ genannt
              </p>

              <strong>1. Vertragsgegenstand</strong>
              <p class="mt-2 mb-5">
                PA ist alleinige Eigentümerin der PATHADVICE Technologie sowie aller zugehörigen Handelsmarken und räumt dem PARTNER das nicht ausschließliche,
                einfache Recht zur Nutzung der in Anlage 1 beschriebenen PA-Services zur gewerblichen Verwendung im Rahmen seiner Kundenkommunikation und
                Kunden-Akquise für die Laufzeit dieser Lizenzvereinbarung ein. Eine Weiter-Lizensierung oder Weitergabe an Dritte sowie die Auswertung oder
                Änderungen des Quellcodes ist nicht zulässig.
              </p>

              <strong>2. Vertragspflichten der PA </strong>
              <p class="mt-2 mb-5">
                PA stellt diese Services basierend auf den Service Levels in Anlage 2 zur Verfügung und ist berechtigt, zur Erbringung von Leistungen
                Subunternehmer hinzuzuziehen.
              </p>

              <strong>3. Vertragspflichten des Partners</strong>
              <p class="mt-2 mb-5">
                Der PARTNER wird PA bei der Erfüllung der vertraglichen Leistungen insbesondere durch die Bereitstellung eines internen Ansprechpartners,
                fachkundiger Mitarbeiter und Informationen und Kommunikationsmittel auf eigene Kosten unterstützen.
                <br /><br />
                Der PARTNER ist im Zusammenhang mit der Nutzung von PA – Services für die Einhaltung der geltenden rechtlicher Vorschriften verantwortlich und
                wird über PA-Systeme keine Informationen austauschen die gegen gesetzliche Verbote, die guten Sitten (insbesondere pornografische, rassistische,
                ausländerfeindliche, rechtsradikale oder sonstige verwerfliche Inhalte) oder Rechte Dritter (insbesondere Persönlichkeits-, Marken-, Namens- und
                Urheberrechte) verstoßen.

                <br /><br />
                Der Partner wird zur Einschulung auf PA-Produkte, die von PA zur Verfügung gestellten Schulungsmaterialien nutzen. In bestimmten Fällen ist für
                die Nutzung von PA-Produkten die erfolgreiche Absolvierung von Schulungsmaßnahmen erforderlich.
                <br /><br />
                Der PARTNER stimmt zu, dass PA Namen und Logo des PARTNERS für werbliche Zwecke als Referenz nutzt sowie, dass PA die Daten des PARTNERS zur
                laufenden Weiterentwicklung seiner Services auf anonymisierte Basis während und nach der Zusammenarbeit mit dem PARTNER nutzt.
                <br /><br />
                Der PARTNER wird die PA Marken im Rahmen der Nutzung der PA-Services entsprechend Anlage 3 darstellen.
              </p>

              <strong>4. Datenschutz</strong>
              <p class="mt-2 mb-5">
                Im Zusammenhang mit der Nutzung von PA-Services agiert der PARTNER als Verantwortlicher im Sinne der DSGVO. PA agiert als Auftrags
                Datenverarbeiter gemäß Art. 28 DSGVO und entsprechend dem Auftragsverarbeitungsvertrag in Anlage 4. Der PARTNER stimmt zu, dass PA Daten zur
                Verbesserung seiner Services auf pseudoanonymisierter und statistischer Basis auswertet.
              </p>
              <strong>5. Vergütung</strong>
              <p class="mt-2 mb-5">
                Für die in dieser Lizenzvereinbarung vereinbarten Leistungen stellt PA Rechnungen in der Höhe und zu den Konditionen wie in Anlage 5
                dargestellt.
              </p>

              <strong>6. Leistungsänderungen </strong>
              <p class="mt-2 mb-5">
                Leistungsänderungen werden in zusätzlichen Anlagen zu dieser Lizenzvereinbarung vereinbart.
                <br /><br />
                PA ist berechtigt, Erweiterung des Leistungsumfangs ohne Abstimmung durchzuführen, wenn dies keine technischen oder rechtlichen Änderungen für
                den PARTNER hervorruft. <br /><br />
                Leistungsänderungen, die technische oder rechtliche Änderungen beim PARTNER hervorrufen werden von PA mit 1 – Monatsfrist vorangekündigt.
                <br /><br />
                Leistungsänderungen, die durch den PARTNER beauftragt werden, werden entsprechend dem vorangehenden Anbot der PA umgesetzt und als
                Individualisierungsaufwand in Rechnung gestellt.
              </p>

              <strong>7. Mängel</strong>
              <p class="mt-2 mb-5">
                Der PARTNER hat binnen 14 Tagen nach Aufforderung durch angemessene Abnahmetests die Funktion des Vertragsgegenstandes zu überprüfen.
                <br /><br />
                Der PARTNER ist verpflichtet, Mängel unverzüglich nach Auftreten an PA zu melden. Die Bearbeitung von Mängeln durch PA ist in Anlage 2 geregelt.
                Die Einmeldung eines Mangels berechtigt den PARTNER nicht zu einer Reduktion der Vergütung bis zur Mängelbehebung.
              </p>

              <strong>8. Zahlungsverzug durch den PARTNER</strong>
              <p class="mt-2 mb-5">
                Der PARTNER ist verpflichtet, Lizenzgebühren gemäß dieser Vereinbarung so zu überweisen, dass diese fristgerecht auf dem Konto der PA einlangen.
                Im Fall der nicht fristgerechten Überweisung ist PA berechtigt, wahlweise den Account des PARTNERS bis zum Eintreffen der Zahlung zu sperren
                oder gänzlich zu deaktivieren. Die Kündigungsfristen gemäß dieser Vereinbarung bleiben für diesem Fall aufrecht.
              </p>

              <strong>9. Laufzeit und Kündigung</strong>

              <p class="mt-2 mb-5">
                Diese Lizenzvereinbarung tritt mit der Unterzeichnung durch die Parteien in Kraft und wird auf unbestimmte Zeit geschlossen.
                <br /><br />
                Die PARTEIEN können diese Lizenzvereinbarung einschließlich aller ihrer Anhänge bei monatlicher Zahlung unter Einhaltung einer 1-monatigen
                Kündigungsfrist, beginnend mit dem 1. des Folgemonats per schriftlicher Mitteilung, an die in Anlage 6 genannte E-Mail-Adresse kündigen.
                <br /><br />

                Die PARTEIEN können diese Lizenzvereinbarung einschließlich aller ihrer Anhänge bei jährlicher Zahlung unter Einhaltung einer 1-monatigen
                Kündigungsfrist, beginnend mit dem 2. Jahr per schriftlicher Mitteilung, an die in Anlage 6 genannte E-Mail-Adresse kündigen. <br /><br />

                <strong>Kündigung aus wichtigem Grund</strong> <br /><br />

                Jede Partei kann diese Lizenzvereinbarung mit sofortiger Wirkung aus wichtigem Grund kündigen. Ein wichtiger Grund liegt insbesondere vor, wenn
                die andere Partei ihre Pflichten nach dieser Lizenzvereinbarung oder nach anwendbarem Recht in wesentlichem Umfang nicht beachtet oder nicht
                erfüllt oder wenn die andere Partei

                <br />
                (1) freiwillig oder unfreiwillig ein Konkurs-, Insolvenz-, Liquidations- oder ähnliches Verfahren einleitet (oder je nach Fall, Gegenstand
                hiervon wird) oder einen entsprechenden Antrag einreicht (und der Antrag, sofern es sich um ein freiwilliges Einleiten handelt, nicht innerhalb
                von sechzig (60) Kalendertagen abgelehnt wird), <br />
                (2) die Bestellung eines Verwalters, Treuhänders, Betreuers oder einer ähnlichen Person beantragt oder dieser zustimmt, für sich oder in Bezug
                auf einen wesentlichen Teil ihres Eigentums oder Vermögens, <br />
                (3) die Auflösung oder Liquidation seines Geschäftsbetriebs oder seiner Geschäfte einleitet, oder <br />
                (4) gesellschaftsrechtliche Handlungen zur Durchführung des Vorgenannten vornimmt.
                <br /><br />
                Kündigungen erfolgen in schriftlicher Form über die in Anlage 6 genannten Kommunikationskanäle. <br /><br />

                <strong>Kündigungsfolgen</strong>
                Der PARTNER hat mit dem letzten Tag der Laufzeit dieser Lizenzvereinbarung die Nutzung der PA-Services einzustellen. <br /><br />
                Der PARTNER wird etwaige Datenexporte, soweit zulässig, vor der Beendigung der Laufzeit durchführen und nimmt zur Kenntnis, dass der
                LogIn-Bereich ab dem 1. Tag nach Beendigung der Laufzeit nicht mehr nutzbar ist.
                <br /><br />
                Der PARTNER hat nach Ermessen der PA unverzüglich alle vertraulichen Informationen die er in seiner Eigenschaft als PARTNER in irgendeiner Form
                erhalten hat entweder zu vernichten oder an PA zurückzugeben, sofern nicht gemäß geltendem Recht anders vorgesehen.
              </p>

              <strong>10. Haftung</strong>
              <p class="mt-2 mb-5">
                PA gibt keine Zusicherung oder Garantie ab, dass die PA-Technologie frei von Mängeln und/oder Fehlern ist und stellt die PA-Services auf Basis
                der Service Levels in Anlage 2, jedoch ohne weitere ausdrücklichen oder konkludenten Garantien bereit.
                <br /><br />
                Wenn Kardinalpflichten durch leichte Fahrlässigkeit verletzt werden und in der Folge die Erreichung des Ziels der Lizenzvereinbarung gefährdet
                ist oder wenn Pflichten, deren Erfüllung wesentliche Voraussetzung für die ordnungsgemäße Erfüllung der Lizenzvereinbarung ist durch leicht
                fahrlässiges Verschulden verletzt werden ist die Haftung der PA auf den für die Lizenzvereinbarung typischen vorhersehbaren Schaden beschränkt.
                In allen sonstigen Fällen ist die Haftung für Schäden aufgrund von leichter Fahrlässigkeit ausgeschlossen.
              </p>

              <strong>11. Geheimhaltung</strong>
              <p class="mt-2 mb-5">
                Die PARTEIEN dürfen vertrauliche Informationen nur dann offenlegen, wenn ihre Offenlegung nach geltendem Recht vorgeschrieben ist. <br /><br />
                Die PARTEIEN beschränken den Zugang zu vertraulichen Informationen auf Personen, die diesen Zugang begründetermaßen benötigen, und stellen
                sicher, dass sämtliche Personen mit Zugang zu den vertraulichen Informationen zur strengen Geheimhaltung verpflichtet sind. <br /><br />
                Die PARTEIEN werden der jeweils andere PARTEI unverzüglich über Sicherheitsverstöße mit möglicher Kompromittierung von vertraulichen
                Informationen, über die widerrechtliche Aneignung, Offenlegung oder Verwendung von vertraulichen Informationen informieren und unternehmen auf
                eigene Kosten im angemessenen Rahmen jegliche Schritte zur Begrenzung, Beendigung oder sonstigen Bekämpfung dieser Zweckentfremdung, Offenlegung
                oder Verwendung.Die PARTEIEN werden der jeweils andere PARTEI unverzüglich über Sicherheitsverstöße mit möglicher Kompromittierung von
                vertraulichen Informationen, über die widerrechtliche Aneignung, Offenlegung oder Verwendung von vertraulichen Informationen informieren und
                unternehmen auf eigene Kosten im angemessenen Rahmen jegliche Schritte zur Begrenzung, Beendigung oder sonstigen Bekämpfung dieser
                Zweckentfremdung, Offenlegung oder Verwendung.
              </p>

              <strong>12. Mitteilungen</strong>
              <p class="mt-2 mb-5">
                Alle Mitteilungen, Anträge, Forderungen und sonstigen Nachrichten im Rahmen dieser Lizenzvereinbarung sind schriftlich an die in Anlage 6
                genannten Adressen zu richten.
              </p>

              <strong>13. Salvatorische Klausel</strong>
              <p class="mt-2 mb-5">
                Sollte eine Bestimmung dieser Lizenzvereinbarung ungültig oder nicht durchsetzbar sein oder werden, so bleibt die Gültigkeit oder
                Durchsetzbarkeit der übrigen Bestimmungen hiervon unberührt. Die ungültige oder nicht durchsetzbare Bestimmung gilt als durch eine geeignete und
                angemessene Bestimmung, die der wirtschaftlichen Absicht und dem Zweck der ungültigen oder nicht durchsetzbaren Bestimmung, soweit gesetzlich
                zulässig, am nächsten kommt, ersetzt.
              </p>
              <strong>14. Rechtswahl und Gerichtsstand </strong>
              <p class="mt-2 mb-5">
                Diese Lizenzvereinbarung unterliegt deutschem Recht für Vertragspartner mit Sitz in der Europäischen Union und dem Recht von Großbritannien für
                Vertragspartner außerhalb der Europäischen Union. Der ausschließliche Gerichtsstand für alle Streitigkeiten und Verfahren, die aus oder in
                Zusammenhang mit dieser Lizenzvereinbarung entstehen, ist Innsbruck.
              </p>

              <strong>Anlage 1 / Leistungsbeschreibung PATHADVICE Connect </strong><br />
              <div class="mt-5 mb-5">
                <div class="mb-3">
                  <div class="underline mb-5">Account Management</div>
                  - Erstellung PARTNER-Account inkls. 1 User<br />
                  - Optional: Erweiterung durch beliebig viele User (kostenpflichtig) <br />
                  - Optional: Zubuchung weiterer Services (kostenpflichtig)
                  <br /><br />
                </div>
                <div class="mb-3">
                  <div class="underline mb-5">Login Bereich</div>
                  - Dashboard <br />
                  - Anzeige Website-Besucher<br />
                  - Dialogfeld Konfiguration<br /><br />
                </div>
                <div class="mb-3">
                  <div class="underline mb-5">Website Besucher Kommunikation</div>
                  - Dialogfeld zur direkten Kontaktaufnahme mit Berater<br />
                  - Invite - Link zur digitalen Beratung<br /><br />
                  - Browserbasierte Videoberatung (CoBrowsing)<br /><br />
                  - Beidseitige Steuerung<br /><br />
                  - Pointer Funktion<br /><br />
                </div>
              </div>

              <strong>Anlage 2 - A5000D_Service Level Agreement</strong><br />
              <div class="mt-5 mb-5">
                <strong>Servicezeiten: Montag bis Freitag an Arbeitstagen, CET 09:00 Uhr bis 17:00 Uhr</strong>
                <p class="mb-3">
                  E-Mail: <a href="mailto:support@pathadvice.at">support@pathadvice.at</a><br />
                  Chat: Im Login Bereich<br /><br />
                </p>
              </div>

              <strong>Severity Level und Reaktionszeiten:</strong><br />
              <vs-row class="left-solid-border">
                <vs-col class="bg-red solid-border text-left" vs-type="flex" vs-justify="center" vs-align="center" vs-w="4"> Dringend </vs-col>
                <vs-col class="solid-border" vs-type="flex" vs-justify="center" vs-align="center" vs-w="4"> Umfassender Betriebsausfall </vs-col>
                <vs-col class="solid-border" vs-type="flex" vs-justify="center" vs-align="center" vs-w="4"> Problemlösung: 1 Arbeitstag </vs-col>
              </vs-row>
              <vs-row class="left-solid-border">
                <vs-col class="bg-orange solid-border text-left" vs-type="flex" vs-justify="center" vs-align="center" vs-w="4"> Hoch </vs-col>
                <vs-col class="solid-border" vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
                  Betriebsunterbrechung bei mehreren Anwendern
                </vs-col>
                <vs-col class="solid-border" vs-type="flex" vs-justify="center" vs-align="center" vs-w="4"> Problemlösung: 1 Arbeitstag </vs-col>
              </vs-row>
              <vs-row class="left-solid-border">
                <vs-col class="bg-yellow solid-border text-left" vs-type="flex" vs-justify="center" vs-align="center" vs-w="4"> Mittel </vs-col>
                <vs-col class="solid-border" vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
                  Fehler tritt in bestimmten Konstellationen auf</vs-col
                >
                <vs-col class="solid-border" vs-type="flex" vs-justify="center" vs-align="center" vs-w="4"> Problemlösung: 10 Arbeitstage </vs-col>
              </vs-row>
              <vs-row class="left-bottom-solid-border">
                <vs-col class="bg-green solid-border text-left" vs-type="flex" vs-justify="center" vs-align="center" vs-w="4"> Niedrig </vs-col>
                <vs-col class="solid-border" vs-type="flex" vs-justify="center" vs-align="center" vs-w="4"> Gering Beeinträchtigung der Services </vs-col>
                <vs-col class="solid-border" vs-type="flex" vs-justify="center" vs-align="center" vs-w="4"> Problemlösung: 10 Arbeitstage </vs-col>
              </vs-row>

              <p class="mt-5 mb-5">Wird ein Ticket außerhalb der Arbeitszeiten aufgegeben beginnen Service-Laufzeiten mit dem folgenden Arbeitstag.</p>

              <strong>Anlage 3 / A6000D_Markenverwendung</strong><br />
              <div class="mt-5 mb-5">Die Wort-Bild-Marke ist – wo immer möglich – in ihrer Maximalausprägung (abgebildete Standardversion) einzusetzen.</div>

              <div class="flex flex-row items-center flex-wrap justify-around w-full">
                <div class="flex flex-row justify-center style-guide">
                  <div>
                    <img src="/logo.classic.svg" width="120" height="120" class="mr-4 fill-current text-secondary" />
                  </div>
                  <div class="classic">
                    <div class="mt-5 text-secondary">PATHADVICE</div>
                  </div>
                </div>
                <div class="flex flex-col" style="font-weight: 400">
                  <div style="width: 160px; height: 82px; background-color: #12598d"></div>
                  <div style="padding: 10px">
                    <strong>Primär</strong> <br /><br />
                    HEX: #12598d <br />
                    RGB: 18/ 89/ 141 <br />
                    CMYK: 93 / 62 / 21 / 6
                  </div>
                </div>
              </div>

              <div class="mt-12"><u>Schriftart:</u> Lato SemiBold</div>

              <div class="mt-12"><strong>Anlage 4 / A3200D_Auftragsdatenverarbeitungsvertrag</strong><br /></div>
              <div class="mt-6">zwischen dem Auftragsverarbeiter</div>
              <div class="mt-6">
                <strong>PATHADVICE International GmbH</strong><br />
                Serlesweg 3<br />
                6161 Natters<br />
                Austria<br /><br />
                (nachfolgend „PA“ genannt)<br /><br />
                und dem „PARTNER“ <br />
                gemeinsam nachfolgend „PARTEIEN“ genannt
              </div>

              <div class="mt-12">
                <strong>1. Gegenstand der Vereinbarung</strong>
                <p>Gegenstand, Art, Umfang sowie Zweck der Datenverarbeitung ergibt sich aus dem zwischen PA und dem PARTNER geschlossenen Lizenzvertrag.</p>
                <p>
                  Die Verarbeitung der Daten umfasst das Erheben, Erfassen, Ordnen, Speichern, Anpassen, Verändern, Auslesen, Abfragen, Bereitstellen,
                  Abgleichen, Einschränken, Löschen und Vernichten von Daten.
                </p>
                <p>
                  Die erhobenen Daten werden dem PARTNER im Rahmen einer cloud-basierten Softwarelösung zur Verfügung gestellt. Zur Wartung und Unterstützung
                  des PARTNERS erhalten Mitarbeiter der PA Zugang zu diesen Daten.
                </p>
                <p><u>Folgende Datenkategorien werden im Rahmen der Kooperation verarbeitet:</u></p>
                <p>Kunden, Interessenten, Lieferanten, Ansprechpartner, Beschäftigte, Website-Besucherdaten;</p>
              </div>

              <div class="mt-12">
                <strong>2. Dauer der Vereinbarung</strong>
                <p>Dieser Vertrag wird auf unbestimmte Zeit geschlossen und gilt bis zur Beendigung des PATHADVICE Lizenzvertrages.</p>
              </div>

              <div class="mt-12">
                <strong>3. Pflichten der PA</strong>
                <ol class="number mt-2 mb-5">
                  <li>
                    PA verpflichtet sich, Daten und Verarbeitungsergebnisse ausschließlich im Rahmen der schriftlichen Aufträge des PARTNERS zu verarbeiten.
                    Erhält PA einen behördlichen Auftrag, Daten des PARTNER herauszugeben, so hat er - sofern gesetzlich zulässig - den PARTNER unverzüglich
                    darüber zu informieren und die Behörde an diesen zu verweisen.
                  </li>
                  <li>
                    PA wird die Daten für eigene Zwecke ausschließlich anonymisiert und auf statistischer Basis zur Durchführung sowie zur Verbesserung der
                    PA-Services verarbeiten.
                  </li>
                  <li>
                    PA erklärt rechtsverbindlich, dass alle mit der Datenverarbeitung beauftragten Personen vor Aufnahme der Tätigkeit zur Vertraulichkeit
                    verpflichtet hat oder diese einer angemessenen gesetzlichen Verschwiegenheitsverpflichtung unterliegen. Insbesondere bleibt die
                    Verschwiegenheitsverpflichtung der mit der Datenverarbeitung beauftragten Personen auch nach Beendigung ihrer Tätigkeit und Ausscheiden beim
                    PA aufrecht.
                  </li>
                  <li>
                    PA erklärt rechtsverbindlich, dass sie alle erforderlichen Maßnahmen zur Gewährleistung der Sicherheit der Verarbeitung nach Art 32 DSGVO
                    ergriffen hat (Einzelheiten sind der Anlage ./1 zu entnehmen).
                  </li>
                  <li>
                    PA ergreift die technischen und organisatorischen Maßnahmen, damit der PARTNER die Rechte der betroffenen Person nach Kapitel III der DSGVO
                    (Information, Auskunft, Berichtigung und Löschung, Datenübertragbarkeit, Widerspruch, sowie automatisierte Entscheidungsfindung im
                    Einzelfall) innerhalb der gesetzlichen Fristen jederzeit erfüllen kann und überlässt dem PARTNER alle dafür notwendigen Informationen. Wird
                    ein entsprechender Antrag an PA gerichtet und lässt dieser Antrag erkennen, dass der Antragsteller PA irrtümlich für den Verantwortlichen
                    der von ihm betriebenen Datenverarbeitung hält, hat PA den Antrag unverzüglich an den PARTNER weiterzuleiten und dies dem Antragsteller
                    mitzuteilen.
                  </li>
                  <li>
                    PA unterstützt den PARTNER bei der Einhaltung der in den Art 32 bis 36 DSGVO genannten Pflichten (Datensicherheitsmaßnahmen, Meldungen von
                    Verletzungen des Schutzes personenbezogener Daten an die Aufsichtsbehörde, Benachrichtigung der von einer Verletzung des Schutzes
                    personenbezogener Daten betroffenen Person, Datenschutz-Folgeabschätzung, vorherige Konsultation).
                  </li>
                  <li>
                    Der PARTNER wird darauf hingewiesen, dass er für die vorliegende Auftragsverarbeitung ein Verarbeitungsverzeichnis nach Art 30 DSGVO zu
                    errichten hat.
                  </li>
                  <li>
                    Dem PARTNER wird hinsichtlich der Verarbeitung der von ihm überlassenen Daten das Recht jederzeitiger Einsichtnahme und Kontrolle, sei es
                    auch durch von ihm beauftragte Dritte, der Datenverarbeitungseinrichtungen eingeräumt. PA verpflichtet sich, dem PARTNER jene Informationen
                    zur Verfügung zu stellen, die zur Kontrolle der Einhaltung der in dieser Vereinbarung genannten Verpflichtungen notwendig sind.
                  </li>
                  <li>
                    PA ist nach Beendigung dieser Vereinbarung verpflichtet, alle Verarbeitungsergebnisse und Unterlagen, die Daten enthalten im Auftrag des
                    PARTNERS zu vernichten. Wenn PA die Daten in einem speziellen technischen Format verarbeitet, ist PA verpflichtet, die Daten nach Beendigung
                    dieser Vereinbarung in diesem Format oder in einem anderen, gängigen Format herauszugeben.
                  </li>
                  <li>
                    PA hat den PARTNER unverzüglich zu informieren, falls er der Ansicht ist, eine Weisung des PARTNER verstößt gegen Datenschutzbestimmungen
                    der Union oder der Mitgliedstaaten.
                  </li>
                </ol>
              </div>

              <div class="mt-12">
                <strong>4. Pflichten des PARTNERS</strong>
                <p>
                  Für die Beurteilung der Zulässigkeit der beauftragten Verarbeitung sowie für die Wahrung der Rechte von Betroffenen ist allein der PARTNER
                  verantwortlich.
                </p>
              </div>

              <div class="mt-12">
                <strong>5. Technische und organisatorische Maßnahmen</strong>
                <p>
                  Die Datensicherheitsmaßnahmen sind aus Anlage 1 ersichtlich. Datensicherheitsmaßnahmen können der technischen und organisatorischen
                  Weiterentwicklung entsprechend angepasst werden, solange das hier vereinbarte Niveau nicht unterschritten wird.
                </p>
              </div>

              <div class="mt-12">
                <strong>6. Ort der Durchführung der Datenverarbeitung</strong>
                <p>Alle Datenverarbeitungstätigkeiten werden innerhalb der Europäischen Union durchgeführt.</p>
              </div>

              <div class="mt-12">
                <strong>7. Sub-Auftragsverarbeiter</strong>
                <p>
                  PA arbeitet abhängig von den zur Verfügung gestellten Services und deren Ausprägung mit verschiedener Sub-Auftragsverarbeiter zusammen. Eine
                  Liste der Sub-Auftragsverarbeiter wird auf Anfrage an <a href="mailto:support@pathadvice.at">support@pathadvice.at</a> zur Verfügung gestellt.
                </p>
                <p>
                  PA kann weitere Sub-Auftragsverarbeiter hinzuziehen. PA hat den PARTNER von der beabsichtigten Heranziehung eines Sub-Auftragsverarbeiters so
                  rechtzeitig zu verständigen, dass der PARTNER dies allenfalls untersagen kann. PA schließt die erforderlichen Vereinbarungen im Sinne des Art
                  28 Abs 4 DSGVO mit dem Sub-Auftragsverarbeiter ab. Dabei ist sicherzustellen, dass der Sub-Auftragsverarbeiter dieselben Verpflichtungen
                  eingeht, die PA auf Grund dieser Vereinbarung obliegen. Kommt der Sub-Auftragsverarbeiter seinen Datenschutzpflichten nicht nach, so haftet PA
                  gegenüber dem PARTNER für die Einhaltung der Pflichten des Sub-Auftragsverarbeiters.
                </p>
              </div>

              <div class="mt-12">
                <strong>8. Beendigung </strong>
                <p>
                  Bei Beendigung des Vertragsverhältnisses oder jederzeit auf Verlangen des PARTNERS hat PA die im Auftrag verarbeiteten Daten zu vernichten.
                  Die Vernichtung hat so zu erfolgen, dass eine Wiederherstellung auch von Restinformationen mit vertretbarem Aufwand nicht mehr möglich ist.
                </p>
                <p>
                  Davon unbeschränkt bleibt das Recht der PA, diese Daten auch nach der Beendigung in anonymisierter Form und auf statistischer Basis zur
                  Verbesserung seiner Services zu nutzen.
                </p>
              </div>

              <div class="mt-12">
                <strong>9. Sonstiges </strong>
                <p>
                  Beide Parteien sind verpflichtet, alle im Rahmen des Vertragsverhältnisses erlangten Kenntnisse von Geschäftsgeheimnissen und
                  Datensicherheitsmaßnahmen der jeweils anderen Partei auch über die Beendigung des Vertrages vertraulich zu behandeln. Bestehen Zweifel, ob
                  eine Information der Geheimhaltungspflicht unterliegt, ist sie bis zur schriftlichen Freigabe durch die andere Partei als vertraulich zu
                  behandeln.
                </p>
              </div>

              <div class="mt-12">
                <strong>Anlage ./1 </strong> <br />
                <strong>Technische und organisatorische Maßnahmen i.S.d. Art. 32 DSGVO </strong> <br />

                <div class="mt-6">
                  <strong>A. VERTRAULICHKEIT</strong>
                </div>

                <div class="mt-2"><strong>Zutrittskontrolle:</strong>Schutz vor unbefugtem Zutritt zu Datenverarbeitungsanlagen durch:</div>
                <table>
                  <tr>
                    <td>X Schlüssel</td>
                    <td>x Magnet- oder Chipkarten</td>
                  </tr>
                  <tr>
                    <td>☐ Elektrische Türöffner</td>
                    <td>☐ Portier</td>
                  </tr>
                  <tr>
                    <td>☐ Sicherheitspersonal</td>
                    <td>x Alarmanlagen</td>
                  </tr>
                  <tr>
                    <td>☐ Videoanlage</td>
                    <td>x Einbruchshemmende Fenster und/oder Sicherheitstüren</td>
                  </tr>
                  <tr>
                    <td>x Anmeldung beim Empfang mit Personenkontrolle</td>
                    <td>x Begleitung von Besuchern im Unternehmensgebäude</td>
                  </tr>
                  <tr>
                    <td>X Tragen von Firmen-/Besucherausweisen</td>
                    <td>☐ Sonstiges:</td>
                  </tr>
                </table>

                <div class="mt-2"><strong>Zugangskontrolle:</strong>Schutz vor unbefugter Systembenutzung durch:</div>
                <table>
                  <tr>
                    <td>x Kennwörter (einschließlich entsprechender Policy)</td>
                    <td>x Verschlüsselung von Datenträgern</td>
                  </tr>
                  <tr>
                    <td>☐ Automatische Sperrmechanismen</td>
                    <td>☐ Sonstiges:</td>
                  </tr>
                  <tr>
                    <td>x Zwei-Faktor-Authentifizierung</td>
                    <td></td>
                  </tr>
                </table>

                <div class="mt-2">
                  <strong>Zugriffskontrolle:</strong>Kein unbefugtes Lesen, Kopieren, Verändern oder Entfernen innerhalb des Systems durch:
                </div>
                <table>
                  <tr>
                    <td>X Standard-Berechtigungsprofile auf „need to know-Basis“</td>
                    <td>X Standardprozess für Berechtigungsvergabe</td>
                  </tr>
                  <tr>
                    <td>X Protokollierung von Zugriffen</td>
                    <td>X Sichere Aufbewahrung von Speichermedien</td>
                  </tr>
                  <tr>
                    <td>x Periodische Überprüfung der vergebenen Berechtigungen, insb von administrativen Benutzerkonten</td>
                    <td>X Datenschutzgerechte Wiederverwendung von Datenträgern</td>
                  </tr>
                  <tr>
                    <td>X Datenschutzgerechte Entsorgung nicht mehr benötigter Datenträger</td>
                    <td>X Clear-Desk/Clear-Screen Policy</td>
                  </tr>
                  <tr>
                    <td>☐ Sonstiges:</td>
                    <td></td>
                  </tr>
                </table>

                <div class="mt-2">
                  <strong>Pseudonymisierung:</strong>Sofern für die jeweilige Datenverarbeitung möglich, werden die primären Identifikationsmerkmale der
                  personenbezogenen Daten in der jeweiligen Datenverarbeitung entfernt, und gesondert aufbewahrt.
                </div>
                <table>
                  <tr>
                    <td>X Ja</td>
                    <td>☐ Nein</td>
                  </tr>
                </table>

                <div class="mt-2">
                  <strong>Klassifikationsschema für Daten:</strong>Sofern für die jeweilige Datenverarbeitung möglich, werden die primären
                  Identifikationsmerkmale der personenbezogenen Daten in der jeweiligen Datenverarbeitung entfernt, und gesondert aufbewahrt.
                </div>
                <table>
                  <tr>
                    <td>X Ja</td>
                    <td>☐ Nein</td>
                  </tr>
                </table>

                <div class="mt-6">
                  <strong>B. DATENINTEGRITÄT</strong>
                </div>

                <div class="mt-2">
                  <strong>Weitergabekontrolle: </strong>Kein unbefugtes Lesen, Kopieren, Verändern oder Entfernen bei elektronischer Übertragung oder Transport
                  durch:
                </div>
                <table>
                  <tr>
                    <td>X Verschlüsselung von Datenträgern</td>
                    <td>X Verschlüsselung von Dateien</td>
                  </tr>
                  <tr>
                    <td>☐ Virtual Private Networks (VPN)</td>
                    <td>☐ Elektronische Signatur</td>
                  </tr>
                  <tr>
                    <td>☐ Sonstiges:</td>
                    <td></td>
                  </tr>
                </table>

                <div class="mt-2">
                  <strong>Eingabekontrolle: </strong>Feststellung, ob und von wem personenbezogene Daten in Datenverarbeitungssysteme eingegeben, verändert oder
                  entfernt worden sind durch:
                </div>
                <table>
                  <tr>
                    <td>X Protokollierung</td>
                    <td>X Dokumentenmanagement</td>
                  </tr>
                  <tr>
                    <td>☐ Sonstiges:</td>
                    <td></td>
                  </tr>
                </table>

                <div class="mt-6">
                  <strong>C. VERFÜGBARKEIT UND BELASTBARKEIT</strong>
                </div>

                <div class="mt-2"><strong>Verfügbarkeitskontrolle: </strong>Schutz gegen zufällige oder mutwillige Zerstörung bzw. Verlust durch:</div>
                <table>
                  <tr>
                    <td>X Backup-Strategie (online/offline; on-site/off-site)</td>
                    <td>☐ Unterbrechungsfreie Stromversorgung (USV, Dieselaggregat)</td>
                  </tr>
                  <tr>
                    <td>X Virenschutz</td>
                    <td>X Firewall</td>
                  </tr>
                  <tr>
                    <td>X Meldewege und Notfallpläne</td>
                    <td>X Security Checks auf Infrastruktur- und Applikationsebene</td>
                  </tr>
                  <tr>
                    <td>☐ Mehrstufiges Sicherungskonzept mit verschlüsselter Auslagerung der Sicherungen in ein Ausweichrechenzentrum</td>
                    <td>X Standardprozesse bei Wechsel/Ausscheiden von Mitarbeitern</td>
                  </tr>
                  <tr>
                    <td>☐ Sonstiges:</td>
                    <td></td>
                  </tr>
                </table>

                <div class="mt-2"><strong>Rasche Wiederherstellbarkeit:</strong></div>
                <table>
                  <tr>
                    <td>X Ja</td>
                    <td>☐ Nein</td>
                  </tr>
                </table>

                <div class="mt-6">
                  <strong>D. VERFAHREN ZUR REGELMÄßIGEN ÜBERPRÜFUNG, BEWERTUNG UND EVALUIERUNG</strong>
                </div>

                <div class="mt-2"><strong>Datenschutz-Management, </strong>einschließlich regelmäßiger Mitarbeiter-Schulungen:</div>
                <table>
                  <tr>
                    <td>X Ja</td>
                    <td>☐ Nein</td>
                  </tr>
                </table>

                <div class="mt-2"><strong>Incident-Response-Management:</strong></div>
                <table>
                  <tr>
                    <td>X Ja</td>
                    <td>☐ Nein</td>
                  </tr>
                </table>

                <div class="mt-2"><strong>Datenschutzfreundliche Voreinstellungen:</strong></div>
                <table>
                  <tr>
                    <td>X Ja</td>
                    <td>☐ Nein</td>
                  </tr>
                </table>

                <div class="mt-2">
                  <strong>Auftragskontrolle: </strong>Keine Auftragsdatenverarbeitung im Sinne von Art 28 DS-GVO ohne entsprechende Weisung des PARTNER durch:
                </div>
                <table>
                  <tr>
                    <td>X Eindeutige Vertragsgestaltung</td>
                    <td>X Formalisiertes Auftragsmanagement</td>
                  </tr>
                  <tr>
                    <td>X Strenge Auswahl des Auftragsverarbeiters (ISO-Zertifizierung, ISMS)</td>
                    <td>☐ Vorabüberzeugungspflicht</td>
                  </tr>
                  <tr>
                    <td>☐ Nachkontrollen</td>
                    <td>☐ Sonstiges:</td>
                  </tr>
                </table>
              </div>

              <div class="mt-12">
                <strong>Anlage ./2 – Sub-Auftragsverarbeiter </strong>
                <p>Google Firebase (europe-west3), domiciled in Frankfurt/Germany; Contractual relationship with Google Irland;</p>
                <p>Hosting services: Surfly BV, Singel 373, 1012 WL Amsterdam/ Netherlands: API für Co-Browsing</p>
              </div>

              <div class="mt-12">
                <strong>Anlage 5 / Preisblatt</strong><br />
                <strong>PATHADVICE Connect</strong><br />

                <p>Für die in Anlage 1 dargestellten Services verrechnet PA Lizenzgebühren:</p>

                <p>
                  <strong><u>49,00 zzgl. MWSt. / Monat </u></strong>
                </p>

                <p>oder</p>

                <p>
                  <strong><u>540,00 zzgl. MWSt. / Jahr</u></strong>
                </p>

                <p>
                  Lizenzgebühren werden mit Zeichnung der Lizenzvereinbarung in Rechnung gestellt und fällig sowie abhängig vom gewählten Zahlungsmittel und der
                  gewählten Zahlungsart zum Beginn der Laufzeit monatlich bzw. jährlich eingezogen.
                </p>

                <p>
                  Im Falle, dass der PARTNER während der Laufzeit dieser Vereinbarung zusätzliche Services ordert, werden die Kosten der zusätzlich georderten
                  Services/Produkte ab dem Folgemonat in Rechnung gestellt.
                </p>
              </div>

              <div class="mt-12">
                <strong>Anlage 6 / Kontaktdaten</strong><br />

                <p class="mt-2 mb-5">
                  Email: <a href="mailto:support@pathadvice.at">support@pathadvice.at</a> <br />
                  Chat: Im Login Bereich<br />
                  Postalisch: PATHADVICE International GmbH, Serlesweg 3, 6161 Natters, Austria
                </p>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  computed: {
    locale() {
      return this.$i18n.locale
    }
  },
  methods: {
    closeLicenseAgreement() {
      this.$serverBus.$emit('close-license-agreement')
    }
  }
}
</script>

<style lang="scss" scoped>
.agreement-text {
  overflow: auto;
  height: 500px;
  background-color: #fff;
  padding: 5px;
}
.agreement-confirmation {
  padding: 20px;
}
.agreement-header {
  display: inline-block;
  width: 100%;
}
.agreement-header-left {
  display: flex;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  width: 50%;
}
.agreement-header-left img {
  width: 60px;
  height: 60px;
}
.agreement-header-right {
  text-align: right;
  display: inline-block;
  font-size: 25px;
  padding-right: 10px;
  width: 50%;
}
.bold {
  font-weight: bold;
}
.black {
  color: #000000;
}
.classic {
  color: rgba(var(--vs-primary), 1) !important;
  font-size: 40px;
}
.fl-left {
  float: left;
}
.fl-right {
  float: right;
}
.color-box {
  width: 120px;
  height: 120px;
}

.font-25 {
  font-size: 25px;
}
.font-18 {
  font-size: 18px;
}

.bg-gray {
  background-color: gray;
}
.bg-lightGray {
  background-color: #f2f2f2;
}
.bg-classic {
  background-color: rgba(var(--vs-primary), 1) !important;
}

.fade-bottom-2x-enter-active,
.fade-bottom-2x-leave-active {
  transition: opacity 0.2s, transform 0.25s;
}
.fade-bottom-2x-enter {
  opacity: 0;
  transform: translateY(-4%);
}

.fade-bottom-2x-leave-to {
  opacity: 0;
  transform: translateY(4%);
}
.vs-list--header,
.vs-list--item {
  overflow-wrap: anywhere;
}
.justify p {
  text-align: justify;
  text-justify: inter-word;
}
.underline {
  text-decoration: underline;
}
.left-solid-border {
  border-color: black;
  border-style: solid;
  border-width: thin;
  border-bottom-style: none;
  border-right-style: none;
  border-top-style: none;
}
.left-bottom-solid-border {
  border-color: black;
  border-style: solid;
  border-width: thin;
  border-right-style: none;
  border-top-style: none;
}
.solid-border {
  color: black;
  border-color: black;
  border-style: solid;
  border-width: thin;
  justify-content: left !important;
  padding: 10px;
  height: 75px;
  border-bottom-style: none;
  border-left-style: none;
}
.bg-red {
  background-color: red;
}
.bg-orange {
  background-color: orange;
}
.bg-yellow {
  background-color: yellow;
}
.bg-green {
  background-color: green;
}
.style-guide {
  font-size: 30px;
  font-weight: bold;
}
.normal {
  font-size: 24px;
  font-weight: normal;
  margin-top: -10px;
}
ol.number {
  counter-reset: number;
  display: table;
}
ol > li {
  counter-increment: number;
  display: table-row;
}
ol.number > li:before {
  content: '(' counter(number, decimal) ') ';
  display: table-cell;
  text-align: justify;
  text-justify: inter-word;
  width: 25px;
}
table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}
th,
td {
  border: 1px solid black;
  padding: 8px;
}
</style>
